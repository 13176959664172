import React from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";

import { RTKLazyQueryWrapper } from "components/DataFetchWrapper";
import { useLazyGetContributionSectorTableQuery } from "api/apiSlice";
import DataGridBasic from "components/Tables/DataGridTable/DataGridBasic";
import YSSwitchPlain from "components/YSSwitchPlain";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import { t } from "i18next";

export const ReturnContributionTable = () => {
  const { endDate, startDate } = useSelector((state) => state.user);
  const { currency } = useSelector(
    (state) => state.user.chosen_client.client_info_fa
  );
  const [localOrBase, setLocalOrBase] = React.useState("base");
  const [contributionOrReturn, setContributionOrReturn] =
    React.useState("return");
  const query = useLazyGetContributionSectorTableQuery();
  const handleLocalOrBaseChange = () => {
    setLocalOrBase((oldState) => (oldState === "base" ? "local" : "base"));
  };
  const handleContributionOrReturnChange = () => {
    setContributionOrReturn((oldState) =>
      oldState === "return" ? "contribution" : "return"
    );
  };
  return (
    <Fade in={true} timeout={2000}>
      <Grid item xs={12} md={12} xl={6} sx={{ mt: 2 }}>
        <Card
          sx={{
            marginTop: "80px",
            marginRight: "40px",
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.primary.light
                : theme.palette.primary.dark,
            overflow: "visible",
            minHeight: "500px",
          }}
        >
          <CardContent
            sx={{
              color: "primary.contrastText",
              height: "500px",
            }}
          >
            <Paper
              sx={{
                height: "550px",
                p: 1,
                textAlign: "center",
                margin: "-80px -40px 0px 0px",
                borderRadius: "0px 0px 0px 0px",
              }}
            >
              <RTKLazyQueryWrapper
                query={query}
                fetchOnMount
                queryParams={{ startDate, endDate }}
              >
                {(data) => {
                  return (
                    <Box
                      sx={{
                        p: 2,
                        display: "flex",
                        flexGrow: 1,
                        flexDirection: "column",
                      }}
                    >
                      <DataGridBasic
                        autoHeight={true}
                        sx={{
                          "& .MuiDataGrid-columnHeaderTitle": {
                            whiteSpace: "normal",
                            lineHeight: "normal",
                            pb: 2,
                            pt: 2,
                          },
                          "& .MuiDataGrid-aggregationColumnHeaderLabel": {
                            color: "primary.contrastText",
                            fontWeight: "bold",
                          },
                          "& .MuiDataGrid-columnHeader": {
                            // Forced to use important since overriding inline styles
                            height: "unset !important",
                          },
                          "& .MuiDataGrid-columnHeaders": {
                            // Forced to use important since overriding inline styles
                            maxHeight: "168px !important",
                            backgroundColor: "primary.main",
                            color: "primary.contrastText",
                          },

                          "& .MuiDataGrid-sortIcon": {
                            color: "white",
                          },
                          "& .MuiDataGrid-columnSeparator": {
                            display: "none",
                          },

                          "& .MuiDataGrid-row": {
                            "&:nth-of-type(even)": {
                              backgroundColor: "info.light",
                              color: "info.contrastText",
                            },
                          },

                          // Add more styles as needed
                        }}
                        data={
                          data ? data[contributionOrReturn][localOrBase] : null
                        }
                      />
                    </Box>
                  );
                }}
              </RTKLazyQueryWrapper>
            </Paper>
          </CardContent>
          <CardActions>
            <Box
              sx={{
                height: "50px",
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                color: "primary.contrastText",
              }}
            >
              <YSSwitchPlain
                isChecked={localOrBase === "local"}
                leftValue={t("financeTerms.base", { currency })}
                rightValue={t("financeTerms.local")}
                onClick={handleLocalOrBaseChange}
              ></YSSwitchPlain>{" "}
              <YSSwitchPlain
                isChecked={contributionOrReturn === "contribution"}
                leftValue={t("financeTerms.return")}
                rightValue={t("financeTerms.twr")}
                onClick={handleContributionOrReturnChange}
              ></YSSwitchPlain>
            </Box>
          </CardActions>
        </Card>
      </Grid>
    </Fade>
  );
};
export default ReturnContributionTable;
