export const peDocumentsEndpoints = (builder) => {
  return {
    getPeDocuments: builder.query({
      query: () => ({
        url: `documents/pe/`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      providesTags: [{ type: "AppState", id: "DOCUMENT_PROCESSING_DOC_LIST" }],
    }),
    updatePeDocument: builder.mutation({
      query: ({ documentId, data }) => ({
        url: `documents/pe/${documentId}/`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: data,
      }),
      invalidatesTags: [
        { type: "AppState", id: "DOCUMENT_PROCESSING_DOC_LIST" },
      ],
    }),
    getPeDocumentTransactions: builder.query({
      query: ({ documentId }) => ({
        url: `documents/pe/${documentId}/transactions/`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      providesTags: [
        { type: "AppState", id: "DOCUMENT_PROCESSING_TRANSACTION_LIST" },
      ],
    }),
    createPeTransaction: builder.mutation({
      query: ({ data }) => ({
        url: `documents/pe/transaction/`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: data,
      }),
      invalidatesTags: [
        { type: "AppState", id: "DOCUMENT_PROCESSING_TRANSACTION_LIST" },
      ],
    }),
    updatePeTransaction: builder.mutation({
      query: ({ transactionId, data }) => ({
        url: `documents/pe/transaction/${transactionId}/`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: data,
      }),
      invalidatesTags: [
        { type: "AppState", id: "DOCUMENT_PROCESSING_TRANSACTION_LIST" },
      ],
    }),
    deletePeTransaction: builder.mutation({
      query: ({ transactionId }) => ({
        url: `documents/pe/transaction/${transactionId}/`,
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      }),
      invalidatesTags: [
        { type: "AppState", id: "DOCUMENT_PROCESSING_TRANSACTION_LIST" },
      ],
    }),
    importCreatedPeTransactions: builder.mutation({
      query: ({ documentId }) => ({
        url: `documents/pe/${documentId}/import/`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }),
      invalidatesTags: [
        { type: "AppState", id: "DOCUMENT_PROCESSING_DOC_LIST" },
      ],
    }),
  };
};
