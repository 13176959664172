import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import ShowcaseComponentMui from "../../../components/ComponentShowcaseMui/ComponenetShowcaseMui";
import { Fab, Typography, Stack, Box, Button, IconButton } from "@mui/material";
import {
  useUpdateUserSettingsMutation,
  useUpdateClientSettingsMutation,
} from "api/apiSlice";
import { ICONS } from "../../../assets/Icons";
import { FormInputRadio } from "../../../components/FormComponents";
import { FormInputColor } from "../../../components/FormComponents";

import { DEFAULTCOLORS, YS_COLORS } from "../../../themes/YsTheme";
import useYsTheme from "hooks/useYsTheme";
const ThemeEditor = () => {
  const ysTheme = useYsTheme();
  const clientID = useSelector((state) => state.user.chosen_client?.id);
  const [updateUserSettings] = useUpdateUserSettingsMutation();
  const [updateClientSettings] = useUpdateClientSettingsMutation();
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isValid, isSubmitting, isValidating, dirtyFields },
    control,
  } = useForm({
    defaultValues: ysTheme
      ? { instanceToUpdate: "userSettings", ...ysTheme }
      : { instanceToUpdate: "userSettings", ...DEFAULTCOLORS },
    mode: "onChange",
  });
  const onSubmit = async (data) => {
    const { instanceToUpdate, ...palette } = data;

    if (instanceToUpdate === "clientSettings" && clientID) {
      await updateClientSettings({
        id: clientID,
        data: { color_scheme: { palette: palette } },
      });
    } else if (instanceToUpdate === "clientTemplateSettings") {
      await updateClientSettings({
        id: clientID,
        data: { template_settings: { color_scheme: { palette: palette } } },
      });
    } else if (instanceToUpdate === "userSettings") {
      await updateUserSettings({ data: { theme: palette } });
    } else {
      alert("please select an instance to update");
    }
  };
  const handleRemoveTheme = async () => {
    await updateUserSettings({ data: { theme: null } });
  };
  return (
    <Fragment>
      <Stack direction={"row"}>
        <Stack
          component={"form"}
          id={"theme-edit-form"}
          onSubmit={handleSubmit(onSubmit)}
          sx={{ m: 1 }}
          minWidth={240}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button variant="contained" onClick={() => reset(YS_COLORS)}>
              {"YS default"}
            </Button>
            <IconButton
              sx={{
                bgcolor: "info.main",
                color: "info.contrastText",
                boxShadow: 2,
              }}
              onClick={() => reset(ysTheme ? ysTheme : DEFAULTCOLORS)}
            >
              {ICONS.reset()}
            </IconButton>
            <IconButton
              sx={{
                bgcolor: "error.main",
                color: "error.contrastText",
                boxShadow: 2,
              }}
              onClick={handleRemoveTheme}
            >
              {ICONS.delete()}
            </IconButton>
          </Box>
          <Box sx={{ m: 1 }}>
            <FormInputRadio
              isRequired={true}
              options={[
                { key: "user", value: "userSettings", label: "User" },
                { key: "client", value: "clientSettings", label: "Client" },
                {
                  key: "client_template",
                  value: "clientTemplateSettings",
                  label: "Client Template",
                },
              ]}
              control={control}
              name={"instanceToUpdate"}
              label="Appply theme to:"
            ></FormInputRadio>
          </Box>
          <Box sx={{ m: 1 }}>
            <Typography variant="h5"> Theme userSettings</Typography>
          </Box>
          <Box sx={{ m: 1 }}>
            <FormInputColor
              name={"primary"}
              label={"Primary Color"}
              control={control}
            ></FormInputColor>
          </Box>
          <Box sx={{ m: 1 }}>
            <FormInputColor
              name={"secondary"}
              label={"Secondary Color"}
              control={control}
            ></FormInputColor>
          </Box>
          <Box sx={{ m: 1 }}>
            <FormInputColor
              name={"tertiary"}
              label={"Tertiary Color"}
              control={control}
            ></FormInputColor>
          </Box>
          <Box sx={{ m: 1 }}>
            <FormInputColor
              name={"info"}
              label={"Info Color"}
              control={control}
            ></FormInputColor>
          </Box>
          <Box sx={{ m: 1 }}>
            <FormInputColor
              name={"success"}
              label={"Success Color"}
              control={control}
            ></FormInputColor>
          </Box>
          <Box sx={{ m: 1 }}>
            <FormInputColor
              name={"warning"}
              label={"Warning Color"}
              control={control}
            ></FormInputColor>
          </Box>
          <Box sx={{ m: 1 }}>
            <FormInputColor
              name={"error"}
              label={"Error Color"}
              control={control}
            ></FormInputColor>
          </Box>
          <Box sx={{ m: 1 }}>
            <FormInputRadio
              name={"mode"}
              label={"Dark mode"}
              options={[
                { key: "light", value: "light", label: "Light" },
                { key: "dark", value: "dark", label: "Dark" },
              ]}
              control={control}
            ></FormInputRadio>
          </Box>
          <Fab
            sx={{ position: "fixed", bottom: "15px", right: "15px" }}
            type="submit"
            form={"theme-edit-form"}
            disabled={!isValid || isSubmitting || isValidating || !dirtyFields}
            variant="extended"
            color="secondary"
            aria-label="save"
          >
            <Typography>{"SAVE"}</Typography>
            {ICONS.saveRounded()}
          </Fab>
        </Stack>
        <ShowcaseComponentMui getValues={getValues}></ShowcaseComponentMui>
      </Stack>
    </Fragment>
  );
};
ThemeEditor.propTypes = {};

export default ThemeEditor;
