import React from "react";
import PropTypes from "prop-types";
import { PlotlyChart } from "../../../components/PlotlyChart";
import { CardContent, Card, Typography, CardActions, Paper, Box } from "@mui/material";
import YSSwitchPlain from "components/YSSwitchPlain";

export default function CustomChart({ data, orientation, Actions, footnote, handleSwitch, switchChecked, ...props }) {
  const { chart_data, chart_config } = data || {};
  const chartFootnote = footnote || chart_config?.footnote;

  return (
    <Card
      elevated="true"
      sx={{
        marginTop: "80px",
        marginRight: orientation === "right" ? "40px" : 0,
        marginLeft: orientation === "left" ? "40px" : 0,
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.primary.light
            : theme.palette.primary.dark,
        overflow: "visible",
        minHeight: "500px",
      }}
    >
      <CardContent>
        <Paper
          sx={{
            height: "100%",
            textAlign: "center",
            margin:
              orientation === "right"
                ? "-80px -40px 0px 0px"
                : "-80px 0px 0px -40px",
            borderRadius: "0px 0px 0px 0px",
          }}
        >
          <Box sx={{ height: "39px", width: "100%" }}></Box>

          <PlotlyChart
            chart_data={chart_data}
            chart_config={chart_config}
            light={false}
            {...props}
          ></PlotlyChart>
          {chartFootnote && (
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ marginTop: "10px", textAlign: 'left', paddingLeft: "50px" }}
            >
              {chartFootnote}
            </Typography>
          )}
          <Box sx={{ height: "39px", width: "100%" }}></Box>
        </Paper>
      </CardContent>

      {Actions && (
        <CardActions sx={{ display: 'flex', justifyContent: 'space-between', padding: '8px 16px' }}>
          {Actions}
          <YSSwitchPlain
            leftValue="Cash"
            rightValue="TWR"
            isChecked={switchChecked}
            onClick={handleSwitch}
          />
        </CardActions>
      )}
    </Card>
  );
}

CustomChart.propTypes = {
  data: PropTypes.object.isRequired,
  orientation: PropTypes.string,
  Actions: PropTypes.node,
  footnote: PropTypes.string,
  handleSwitch: PropTypes.func.isRequired,
  switchChecked: PropTypes.bool.isRequired,
};