import React from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import { DatePresetButton } from "./DatePresetButton";
import {
  month_to_date_range,
  quarterly_to_date_range,
  year_to_date_range,
} from "./utils";
export const DatePresetButtonContainer = ({
  datePresetOptions,
  date,
  onClick,
}) => {
  if (Array.isArray(datePresetOptions)) {
    return (
      <ButtonGroup
        sx={{ pointerEvents: "visible" }}
        variant="contained"
        size="small"
        aria-label="Range selector button group"
      >
        {datePresetOptions.map((item, index) => {
          if (item.id === "ITD") {
            return (
              <DatePresetButton
                date={date}
                text={item.text}
                id={item.id}
                key={item.id}
                dateFunction={item.func}
                onClick={onClick}
              />
            );
          }
          if (item.id === "MTD") {
            return (
              <DatePresetButton
                date={date}
                text={item.text}
                id={item.id}
                key={item.id}
                dateFunction={item.func || month_to_date_range}
                onClick={onClick}
              />
            );
          }

          if (item.id === "YTD") {
            return (
              <DatePresetButton
                date={date}
                text={item.text}
                id={item.id}
                key={item.id}
                dateFunction={item.func || year_to_date_range}
                onClick={onClick}
              />
            );
          }

          if (item.id === "QTD") {
            return (
              <DatePresetButton
                date={date}
                text={item.text}
                id={item.id}
                key={item.id}
                dateFunction={item.func || quarterly_to_date_range}
                onClick={onClick}
              />
            );
          }
        })}
      </ButtonGroup>
    );
  } else {
    return null;
  }
};
