import CPageDashboard from "containers/customPages/cPages003/CPageDashboard";
import CPageFundPerformance from "containers/customPages/cPages003/CPageFundPerformance";
import CPageSecurityPerformance from "containers/customPages/cPages003/CPageSecurityPerformance";
import CPageFundAllocation from "containers/customPages/cPages003/CPageFundAllocation";
import CPageContributors from "containers/customPages/cPages003/CPageContributors";
import CPageNetCashFlow from "containers/customPages/cPages003/CPageNetCashFlow";

export const InvestorRoutes = [
  {
    index: true,
    element: <CPageDashboard />,
  },
  {
    path: "Dashboard",
    element: <CPageDashboard />,
  },
  {
    path: "FundPerformance",
    element: <CPageFundPerformance />,
  },
  {
    path: "SecurityPerformance",
    element: <CPageSecurityPerformance />,
  },
  {
    path: "fundAllocations",
    element: <CPageFundAllocation />,
  },
  {
    path: "largestContributors",
    element: <CPageContributors />,
  },
  {
    path: "lastMonthTransactions",
    element: <CPageNetCashFlow />,
  },
];
