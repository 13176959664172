import React, { memo } from "react";
import CountUp from "react-countup";
import _ from "lodash";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import PropTypes from "prop-types";

//YSUtilities
import { formatValues } from "../utils/functions";

const sizeVal = {
  small: { header: "h6", main: "body1", subtext: "body2" },
  medium: { header: "h5", main: "h4", subtext: "body1" },
  large: { header: "h4", main: "h2", subtext: "h6" },
};
const KeyDataHeader = memo(({ header, data, size, light }) => {
  console.log(data);
  return (
    <Box
      sx={{
        backgroundColor: "primary.main",
        color: "primary.contrastText",
        mt: 1,
        p: 1,
      }}
    >
      <Typography variant={size ? sizeVal[size].header : "h5"}>
        {header}
      </Typography>

      {data && (
        <Grid container>
          {Object.entries(data).map(([key, value], idx) => {
            return (
              <Grid item key={idx} xs>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant={size ? sizeVal[size].subtext : "h5"}>
                    {key}
                  </Typography>
                  <Typography variant={size ? sizeVal[size].main : "h6"}>
                    {_.inRange(value, -10, 10) ? (
                      <CountUp
                        end={value}
                        duration={3}
                        decimals={4}
                        suffix=""
                        formattingFn={(number) => {
                          return formatValues(number, "percent", "", 1);
                        }}
                      />
                    ) : (
                      <CountUp
                        end={value}
                        duration={3}
                        decimals={4}
                        suffix=""
                        formattingFn={(number) => {
                          return formatValues(number, "millions");
                        }}
                      />
                    )}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      )}
    </Box>
  );
});
KeyDataHeader.name = "KeyDataHeader";
KeyDataHeader.propTypes = {
  header: PropTypes.string, // Header for the entire Bar
  data: PropTypes.object, // objectKeys-> [header]:"str",[info]:number
  size: PropTypes.string, //"small", "medium", "large"
  light: PropTypes.bool,
};

export default KeyDataHeader;
