import React from "react";
import { useSelector } from "react-redux";
import { Tabs } from "components/Tabs/Tabs";
import { Box } from "@mui/material";
import { Outlet, useNavigate, useParams } from "react-router-dom";

const ExternalMandatesLayout = () => {
  const client_info = useSelector(
    (state) => state.user.chosen_client.client_info_fa
  );
  const { mandateId } = useParams();
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!mandateId && client_info?.mandatePortfolios[0]) {
      navigate(client_info?.mandatePortfolios[0]?.name);
    }
  }, []);

  return (
    <Box>
      <Tabs
        options={client_info?.mandatePortfolios?.map((item, index) => ({
          key: `${index}`,
          value: item.name,
          label: item.name,
        }))}
        id={"externalMandates"}
      ></Tabs>
      <Outlet />
    </Box>
  );
};

export default ExternalMandatesLayout;
