import React, { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { useTheme } from "@mui/material/styles";
const ParticlesComp = () => {
  const theme = useTheme();
  const particlesInit = useCallback(async (engine) => {
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      options={{
        background: {
          color: {
            value: theme.palette.background,
          },
        },
        fpsLimit: 24,
        particles: {
          number: {
            value: 100,
            density: {
              enable: true,
              value_area: 2000,
            },
          },
          color: {
            value: theme.palette.primary.main,
          },
          shape: {
            type: "polygon",

            polygon: {
              nb_sides: 5,
            },
          },
          opacity: {
            value: {
              max: 1,
              min: 0.2,
            },
            random: true,
            anim: {
              enable: true,
              speed: 0.1,
              opacity_min: 0.1,
              sync: false,
            },
          },
          size: {
            value: 6,
            random: true,
            anim: {
              enable: false,
              size_min: 0.1,
              sync: false,
            },
          },
          line_linked: {
            enable: true,
            distance: 5,
            color: theme.palette.secondary.main,
            opacity: 0.3687847739990702,
            width: 2,
          },
          move: {
            enable: true,
            speed: 1,
            direction: "none",
            random: false,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
          },
        },
        interactivity: {
          detect_on: "window",
          events: {
            onhover: {
              enable: true,
              mode: "connect",
            },
            onclick: {
              enable: true,
              mode: "push",
            },
            resize: true,
          },
        },
        retina_detect: true,
      }}
    />
  );
};

export default ParticlesComp;
