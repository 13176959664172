import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import PresetActionDialog from "./PresetActionDialog";
import { useTranslation } from "react-i18next";
export const NewPresetButton = (props) => {
  const { t } = useTranslation();
  const [isAddingPreset, setIsAddingPreset] = React.useState(false);
  return (
    <React.Fragment>
      <Button
        endIcon={<AddIcon />}
        size="small"
        onClick={() => setIsAddingPreset(true)}
      >
        {t("dataGrid.presets.createButton")}
      </Button>
      <PresetActionDialog
        isAddingPreset={isAddingPreset}
        setIsAddingPreset={setIsAddingPreset}
        {...props}
      />
    </React.Fragment>
  );
};
