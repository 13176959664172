export const tablePresetEndpoints = (builder) => {
  return {
    getPresets: builder.query({
      query: ({ id }) => ({
        url: `state/tables/${id}/presets/`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      providesTags: [{ type: "AppState", id: "TABLE_PRESET" }],
    }),
    createPreset: builder.mutation({
      query: ({ tableId, preset }) => ({
        url: `state/tables/${tableId}/presets/`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: preset,
      }),
      invalidatesTags: [{ type: "AppState", id: "TABLE_PRESET" }],
    }),
    updatePreset: builder.mutation({
      query: ({ id, presetId, preset }) => ({
        url: `state/tables/${id}/presets/${presetId}/`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: preset,
      }),
      invalidatesTags: [{ type: "AppState", id: "TABLE_PRESET" }],
    }),
    deletePreset: builder.mutation({
      query: ({ id, presetId }) => ({
        url: `state/tables/${id}/presets/${presetId}/`,
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      }),
      invalidatesTags: [{ type: "AppState", id: "TABLE_PRESET" }],
    }),
  };
};
