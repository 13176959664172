import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Fade, Grid, Card, CardContent, CardActions } from "@mui/material";
import RTKQueryWrapper from "components/DataFetchWrapper/RTKQueryWrapper";
import YSSwitchPlain from "components/YSSwitchPlain";
import { useGetSecurityReturnsQuery } from "api/apiSlice";
import ChartOne from "../cPages002/ChartOne";
function CPageSecurityPerformance() {
  const startDate = useSelector((state) => state.user.presetStartDate);
  const endDate = useSelector((state) => state.user.endDate);
  const [chartCurrency, setChartCurrency] = React.useState("base");
  const handleCurrency = (e) => {
    e.target.checked ? setChartCurrency("local") : setChartCurrency("base");
  };

  const securityReturnsQuery = useGetSecurityReturnsQuery({
    startDate: startDate,
    endDate: endDate,
    currency: chartCurrency,
  });

  return (
    <Grid container spacing={2}>
      <Fade in={true} timeout={1000}>
        <Grid item xs={12} md={12} xl={12}>
          <Card sx={{ m: 2, minHeight: "100%" }}>
            <CardContent sx={{ p: 0 }}>
              <RTKQueryWrapper query={securityReturnsQuery}>
                {(data) => (
                  <ChartOne data={data} isPercentageData={true}></ChartOne>
                )}
              </RTKQueryWrapper>
              <ChartOne
                componentName={"securityReturns"}
                dataId={"securityReturns"}
                requestParams={[{ key: "currency", value: chartCurrency }]}
              ></ChartOne>
            </CardContent>
            <CardActions>
              <YSSwitchPlain
                leftValue={"Base"}
                rightValue={"Local"}
                isChecked={chartCurrency === "local"}
                onClick={handleCurrency}
              ></YSSwitchPlain>
            </CardActions>
          </Card>
        </Grid>
      </Fade>
    </Grid>
  );
}

export default CPageSecurityPerformance;
