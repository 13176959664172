import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Slide from "@mui/material/Slide";
import { ICONS } from "assets/Icons";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { pageSorter } from "utils/pageSorter";

const drawerWidth = 260;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export function NavBar({ anchor, open, onClose, isPermanent }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const settings = useSelector((store) => store.user.settings);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPage = location.pathname.split("/")[2];
  const sortedPages = settings?.pages?.slice()?.sort(pageSorter);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <MuiDrawer
      variant={isPermanent && !isMobile ? "permanent" : "temporary"}
      anchor="left"
      open={open}
      onClose={onClose}
      sx={{
        width: isPermanent ? "100%" : drawerWidth,

        [`& .MuiDrawer-paper`]: {
          top: "64px",
          height: "calc(100% - 64px)",

          boxSizing: "border-box",
          bgcolor: "transparent",
          boxShadow: "none",
          border: "none",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          msOverflowStyle: "none" /* IE and Edge */,
          scrollbarWidth: "none" /* Firefox */,
        },
      }}
    >
      <List sx={{ width: "100%", pr: 2 }}>
        {sortedPages?.map((route, index) => {
          return (
            <Slide
              key={index}
              in={true}
              direction="right"
              timeout={index * 100}
            >
              <ListItem
                sx={{
                  bgcolor:
                    route.path === currentPage ? "info.dark" : "info.main",
                  color: (theme) =>
                    theme.palette.getContrastText(
                      route.path === currentPage
                        ? theme.palette.info.dark
                        : theme.palette.info.main
                    ),
                  mb: 1,
                  borderTopRightRadius: 50,
                  borderBottomRightRadius: 50,
                }}
                key={route?.key}
                disablePadding
              >
                <ListItemButton
                  onClick={() => {
                    onClose();
                    navigate(route.path);
                  }}
                >
                  <ListItemText
                    primary={t(
                      `navBar.pageTitles.${route?.key.toLowerCase()}`,
                      { defaultValue: route?.key }
                    )}
                  />
                  <ListItemIcon sx={{ justifyContent: "flex-end" }}>
                    {ICONS.arrowRight()}
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            </Slide>
          );
        })}
      </List>
    </MuiDrawer>
  );
}

NavBar.propTypes = {
  anchor: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

export default NavBar;
