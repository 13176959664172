import React, { Suspense } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { SnackbarProvider } from "notistack";
import { SnackbarEffect } from "components/SnackBars/SnackbarEffect";
import { CustomSnackbar } from "components/SnackBars";
import { YS_DEFAULT_THEME } from "./themes/YsTheme";

import CenterContentWrapper from "./containers/CenterContentWrapper";
import { CircularProgress } from "@mui/material";
import MainRoutes from "routes/MainRoutes";
import useYsTheme from "hooks/useYsTheme";

function App() {
  const ysTheme = useYsTheme();
  const theme = createTheme(YS_DEFAULT_THEME(ysTheme));

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        Components={{
          default: CustomSnackbar,
        }}
      >
        <SnackbarEffect />
        <CssBaseline />
        <Suspense
          fallback={
            <CenterContentWrapper>
              <CircularProgress></CircularProgress>
            </CenterContentWrapper>
          }
        >
          <MainRoutes />
        </Suspense>
      </SnackbarProvider>
    </ThemeProvider>
  );
}
App.displayName = "App";

export default App;
