import React from "react";
import { useSelector } from "react-redux";
import { format, parseISO } from "date-fns";
import { Grid, Fade, Card, CardContent } from "@mui/material";
import RTKLazyQueryWrapper from "components/DataFetchWrapper/RTKLazyQueryWrapper";
import ControlledPlotlyChart from "components/PlotlyChart/ControlledPlotlyChart";
import {
  useLazyGetPerformancePeriodBarChartQuery,
  useLazyGetPerformanceTimeSeriesChartQuery,
  useLazyGetReturnBoxesQuery,
} from "api/apiSlice";
import DataCard from "components/Cards/DataCard";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import Typography from "@mui/material/Typography";
export const TimeSeries = () => {
  const startDate = useSelector((state) => state.user.customStartDate);
  const endDate = useSelector((state) => state.user.customEndDate);
  const { t } = useTranslation();
  const performancePeriodBarChartQuery =
    useLazyGetPerformancePeriodBarChartQuery();
  const returnBoxesQuery = useLazyGetReturnBoxesQuery();
  const performanceTimeSeriesChartQuery =
    useLazyGetPerformanceTimeSeriesChartQuery();
  return (
    <Grid container px={2}>
      <Fade in={true} timeout={2000}>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Grid container spacing={2} justifyContent={"center"}>
            <RTKLazyQueryWrapper
              query={returnBoxesQuery}
              queryParams={{
                endDate,
                startDate,
                boxes: ["mtd-twr", "ytd-twr", "inception-twr"].join(","),
              }}
              fetchOnMount
            >
              {(data) => {
                return data?.values?.map((value, index) => (
                  <Grid item xs={12} md={6} lg={3} key={index}>
                    <DataCard
                      height={"100px"}
                      maxWidth={"300px"}
                      {...value}
                      headerName={t(
                        `performancePage.returnBoxes.${value?.headerName}`
                      )}
                      metaData={t(
                        `performancePage.metaData.${value?.headerName}`,
                        {
                          startDate: format(
                            parseISO(value.startDate),
                            "MMM dd, yyyy"
                          ),
                          startDateShort: format(
                            parseISO(value.startDate),
                            "MMM yyyy"
                          ),
                          endDate: format(
                            parseISO(value.endDate),
                            "MMM dd, yyyy"
                          ),
                        }
                      )}
                    />
                  </Grid>
                ));
              }}
            </RTKLazyQueryWrapper>
          </Grid>
        </Grid>
      </Fade>
      <Fade in={true} timeout={2000}>
        <Grid item xs={12} md={12} xl={6} sx={{ mt: 2, p: 1 }}>
          <RTKLazyQueryWrapper
            query={performancePeriodBarChartQuery}
            queryParams={{ startDate, endDate }}
            fetchOnMount
          >
            {(data) => <ControlledPlotlyChart data={data} />}
          </RTKLazyQueryWrapper>
        </Grid>
      </Fade>
      <Fade in={true} timeout={2000}>
        <Grid item xs={12} md={12} xl={6} sx={{ mt: 2 }}>
          <RTKLazyQueryWrapper
            query={performanceTimeSeriesChartQuery}
            queryParams={{ startDate, endDate }}
            fetchOnMount
          >
            {(data) => {
              return <ControlledPlotlyChart data={data} />;
            }}
          </RTKLazyQueryWrapper>
        </Grid>
      </Fade>
    </Grid>
  );
};

export default TimeSeries;
