import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";
export const FormInputText = ({
  classes,
  name,
  control,
  callback,
  label,
  size,
  isRequired,
  type,
  disabled,
  multiline,
  maxLength,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: { value: isRequired, message: "This field is required" },
        maxLength: {
          value: maxLength,
          message: label + " cant be longer than " + maxLength + " characters",
        },
      }}
      render={({ field, fieldState: { error } }) => {
        const { onChange } = field;
        const handleChange = (e) => {
          onChange(e);
          if (callback) {
            callback(e);
          }
        };
        return (
          <TextField
            {...field}
            multiline={multiline}
            onChange={handleChange}
            className={classes?.formInput}
            disabled={disabled}
            variant={"outlined"}
            type={type || "text"}
            fullWidth
            required={!!isRequired}
            size={size ? size : "small"}
            error={!!error}
            helperText={error?.message}
            label={label}
            autoComplete={"off"}
          ></TextField>
        );
      }}
    />
  );
};

FormInputText.propTypes = {
  classes: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  size: PropTypes.string,
  isRequired: PropTypes.bool,
  maxLength: PropTypes.number,
};
export default FormInputText;
