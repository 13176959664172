import React from "react";
import { Box, Chip } from "@mui/material";
import { ICONS } from "assets/Icons";
/**
 * Renders a drop-down chip row group component.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.colDef - The column definition.
 * @param {Object} props.api - The DataGrid API.
 * @param {Object} props.rowNode - The row node.
 * @param {string} props.id - The row ID.
 * @param {string} props.value - The value of the chip.
 * @param {Object} props.COLOR_MAP - The color map for the chip.
 * @returns {JSX.Element|null} The drop-down chip row group component.
 */
const DropDownChipRowGroup = ({
  colDef,
  api,
  rowNode,
  id,
  value,
  COLOR_MAP,
}) => {
  React.useEffect(
    () => {
      if (
        rowNode.isAutoGenerated &&
        rowNode.groupingKey === "received" &&
        !rowNode.childrenExpanded
      ) {
        console.log(
          "DropDownChipRowGroup",
          rowNode.isAutoGenerated,
          rowNode.groupingKey,
          rowNode.childrenExpanded,
          api,
          id
        );
        api.setRowChildrenExpansion(id, true);
      }
    },
    [
      // rowNode.isAutoGenerated,
      // rowNode.groupingKey,
      // rowNode.childrenExpanded,
      // api,
      // id,
    ]
  );
  return rowNode.isAutoGenerated ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        textAlign: "center",
        cursor: "pointer",
        width: `${colDef?.width - 20}px`,
        maxWidth: `200px`,
        fontSize: 2,
      }}
      onClick={() => api.setRowChildrenExpansion(id, !rowNode.childrenExpanded)}
    >
      <Chip
        sx={{ width: "100%", justifyContent: "left" }}
        icon={rowNode.childrenExpanded ? ICONS.arrowDown() : ICONS.arrowRight()}
        label={value?.toUpperCase()}
        color={COLOR_MAP[value] ?? "default"}
      ></Chip>
    </Box>
  ) : null;
};

export default DropDownChipRowGroup;
