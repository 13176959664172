import { Typography, Card, CardContent } from "@mui/material";
import React from "react";
import CountUp from "react-countup";
import { formatValues } from "utils/functions";
import Grid from "@mui/material/Grid";
export function KeyDataCard({ data, containerProps, CustomCardCompent }) {
  if (data === undefined) return null;
  return (
    <Grid container spacing={2} {...containerProps}>
      {Object.entries(data).map(([key, value], index) => {
        if (CustomCardCompent) {
          return (
            <Grid item key={index} xs={12} sm={6} md={4} lg={4} xl={4}>
              <CustomCardCompent header={key} value={value} />
            </Grid>
          );
        }
        if (key === "title") {
          return (
            <Grid item key={index}>
              <Typography>{value.toString()}</Typography>
            </Grid>
          );
        }

        return (
          <Grid item key={index} xs={12} md={6}>
            <Card sx={{ height: "100%" }}>
              <CardContent sx={{ textAlign: "center" }}>
                <Typography variant="h5">{key}</Typography>
                <Typography variant="h6">
                  {typeof value === "string" ? (
                    value
                  ) : (
                    <CountUp
                      end={value}
                      duration={3}
                      decimals={4}
                      suffix=""
                      formattingFn={(number) => {
                        return formatValues(number, "millions");
                      }}
                    />
                  )}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
}
