import React from "react";
import PropTypes from "prop-types";
import { useRouteError } from "react-router-dom";

function Error(props) {
  const error = useRouteError();
  return <div>{error.message}</div>;
}

Error.propTypes = {};

export default Error;
