import { forwardRef } from "react";

import { Button, CircularProgress } from "@mui/material";
/**
 * A button component that supports loading state with a circular progress indicator.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.loading - Whether the button is in loading state.
 * @param {string} props.text - The text to display on the button.
 * @param {string} props.type - The type of the button.
 * @param {Object} props.sx - The style object for the button.
 * @param {string} props.size - The size of the button.
 * @param {string} props.color - The color of the button.
 * @param {string} props.variant - The variant of the button.
 * @param {boolean} props.disabled - Whether the button is disabled.
 * @param {function} props.onClick - The click event handler for the button.
 * @param {ReactNode} props.endIcon - The icon to display at the end of the button.
 * @param {ReactNode} props.children - The content of the button.
 * @param {boolean} props.fullWidth - Whether the button should take up the full width.
 * @param {React.Ref} ref - The ref object for the button.
 * @returns {JSX.Element} The rendered LoadingButton component.
 */
const LoadingButton = forwardRef(
  (
    {
      loading,
      text,
      type,

      sx,
      size,
      color,
      variant,
      disabled,
      onClick,
      endIcon,
      children,
      fullWidth,
      ...rest
    },
    ref
  ) => {
    return (
      <Button
        ref={ref}
        fullWidth={fullWidth}
        type={type}
        sx={{
          ...sx,
          justifyContent: "space-between",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        size={size}
        variant={variant || "default"}
        color={color}
        endIcon={loading ? <CircularProgress size={"1rem"} /> : endIcon}
        disabled={disabled}
        onClick={onClick}
        aria-disabled={loading || disabled}
        {...rest}
      >
        {children ? children : text}
      </Button>
    );
  }
);

LoadingButton.propTypes = {};

export default LoadingButton;
