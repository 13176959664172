import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Axios from "axios";

const Downloader = ({ file, remove }) => {
  const date = useSelector((state) => state.user.endDate);
  const token = useSelector((state) => state.auth.token);
  const [downloadInfo, setDownloadInfo] = useState({
    progress: 0,
    completed: false,
    total: 0,
    loaded: 0,
  });

  useEffect(() => {
    const options = {
      onDownloadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        setDownloadInfo({
          progress: Math.floor(loaded),
          loaded,
          total,
          completed: false,
        });
      },
    };
    let url = "fadata/download_fa_document/";
    let source = Axios.CancelToken.source();
    let headers = {
      Authorization: "Token " + token,
    };
    let body = new FormData();
    body.append("date", date);
    body.append("fileName", file?.fileName);
    body.append("fullPath", file?.fullPath);
    Axios.post(`${import.meta.env.VITE_API_URL}/${url}`, body, {
      responseType: "blob",
      headers: headers,
      cancelToken: source.token,
      ...options,
    }).then(function (response) {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: response.headers["content-type"],
        })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file.fileName);
      document.body.appendChild(link);
      link.click();

      setDownloadInfo((info) => ({
        ...info,
        completed: true,
      }));

      setTimeout(() => {
        remove();
      }, 4000);
      return () => {
        source.cancel("axios request cancelled");
      };
    });
  }, []);

  const formatBytes = (bytes) => `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
  return (
    <div>
      {downloadInfo.loaded > 0 && (
        <>
          <span
            style={{
              padding: "0.5rem",
              marginRight: "0.5rem",
              fontStyle: "bold",
            }}
          >
            {formatBytes(downloadInfo.loaded)}
          </span>
        </>
      )}

      {downloadInfo.loaded === 0 && <>Initializing...</>}
      {/* {downloadInfo.completed &&
        enqueueSnackbar(`Download of ${file?.fileName} completed!`, {
          variant: "success",
        })} */}
    </div>
  );
};
export default Downloader;
