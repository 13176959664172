import { useTranslation as useOriginalTranslation } from "react-i18next";

/**
 * Custom hook to extend the functionality of the `t` function.
 */
export const useExtendedTranslation = () => {
  const { t: originalT, i18n } = useOriginalTranslation();

  /**
   * Extended translation function.
   *
   * @param {string|object} keyOrObject - Translation key or object with translation details.
   * @param {object} [options] - Translation options if the first argument is a string.
   * @returns {string} - Translated string.
   */
  const t = (keyOrObject, options) => {
    if (typeof keyOrObject === "object") {
      const { key, value, translateValue } = keyOrObject;

      let translatedValue = value;
      if (translateValue) {
        // Translate the value if required.
        translatedValue = originalT(value);
      }

      // Use the original `t` function with the dynamic value.
      return originalT(key, { ...options, value: translatedValue });
    }

    // Fallback to the original `t` function if the argument is not an object.
    return originalT(keyOrObject, options);
  };

  return { t, i18n };
};

export default useExtendedTranslation;
