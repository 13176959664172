import {
  startOfMonth,
  startOfYear,
  startOfQuarter,
  parseISO,
  endOfMonth,
} from "date-fns";
export const month_to_date = (date) => {
  const dateObject = parseISO(date);
  return startOfMonth(dateObject);
};
export const year_to_date = (date) => {
  const dateObject = parseISO(date);
  return startOfYear(dateObject);
};
export const quarterly_to_date = (date) => {
  const dateObject = parseISO(date);
  return startOfQuarter(dateObject);
};
export const get_today = (date) => {
  return new Date();
};
export const get_end_of_month = (date) => {
  return endOfMonth(parseISO(date));
};

export const month_to_date_range = (endDate) => {
  const dateObject = parseISO(endDate);
  return [startOfMonth(dateObject), dateObject];
};
export const year_to_date_range = (endDate) => {
  const dateObject = parseISO(endDate);
  return [startOfYear(dateObject), dateObject];
};
export const quarterly_to_date_range = (endDate) => {
  const dateObject = parseISO(endDate);
  return [startOfQuarter(dateObject), dateObject];
};
