const formatValues = (value = 0, key, currency = "", decimals = 0) => {
  if (value === 0) {
    return "-";
  }
  switch (key) {
    //if The number is a percent number between 0 and 1;
    case "percent":
      if (isNaN(value)) {
        return value;
      }
      if (value === 0) {
        return "-";
      }
      return (value * 100).toFixed(decimals) + "%";

    //if the number should be displayed in millions
    case "millions":
      if (isNaN(value)) {
        return value;
      }
      if (value === 0 || value === null) {
        return "-";
      }
      // When value is above 1000m we reformat with a space delimiter for every three digit so 1000m => 1 000m
      if (value > 1000000000) {
        return (
          (value / 1000000).toFixed(0).replace(/(\d)(?=(\d{3})+$)/g, "$1 ") +
          "m" +
          currency
        );
      }
      //absolute value less than one million
      if (value < 1000000 && value > -1000000) {
        return (value / 1000000).toFixed(1) + "m" + currency;
      }
      //Default millions output set to no decimals
      return (value / 1000000).toFixed(0) + "m" + currency;

    case "billions":
      return (value / 1000000000).toFixed(1) + "bn" + currency;

    case "small":
      if (value === 0) {
        return "-";
      }
      if (value > 100000) {
        return value.toFixed(0).replace(/(\d)(?=(\d{3})+$)/g, "$1 ") + currency;
      }
      return value.toFixed(1);

    case "integer":
      if (value === 0) {
        return "-";
      }
      return value.toFixed(0).replace(/(\d)(?=(\d{3})+$)/g, "$1 ") + currency;

    case "priceFormat":
      if (value === 0) {
        return "-";
      }
      if (value > 1000 || value < -1000) {
        return value.toFixed(0).replace(/(\d)(?=(\d{3})+$)/g, "$1 ") + currency;
      }
      return value.toFixed(2);

    case "priceFormatDetail":
      if (value === 0) {
        return "-";
      }
      var split = value.toString().split(".");
      var front = split[0];
      var back = split[1];
      return (
        front.replace(/(\d)(?=(\d{3})+$)/g, "$1 ") +
        `${back ? "." + back.substring(0, 2) : ".00"}` +
        currency
      );

    case "number":
      return Number((value / 1000000).toFixed(1));

    case "simple":
      return Number(value.toFixed(1));

    case "return":
      if (value > 0) {
        return (
          "+" +
          (value / 1000000).toFixed(0).replace(/(\d)(?=(\d{3})+$)/g, "$1 ") +
          "m" +
          currency
        );
      }
      return (value / 1000000).toFixed(1) + "m ";

    case "returnpercent":
      if (value > 0) {
        return "+" + (value * 100).toFixed(1) + "% ";
      }
      return (value * 100).toFixed(1) + "%";

    default:
      return value;
  }
};
const getDataWithKey = (array, key) => {
  if (Array.isArray(array)) {
    var data = null;
    array.forEach((element) => {
      const keyTemp = Object.keys(element)[0];
      if (keyTemp === key) {
        data = element[keyTemp];
      }
    });
    return data;
  }
  return null;
};
function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

function getDate(date = new Date()) {
  if (!date) {
    return null;
  }
  var dd = (date.getDate() < 10 ? "0" : "") + date.getDate();
  var mm = (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1);
  var yyyy = date.getFullYear();
  return yyyy + "-" + mm + "-" + dd;
}
/**
 *
 * @param {number} value
 * @param {string} currency
 * @param {number} decimals
 * @returns number
 */

function formatCurrency(value, currency, decimals) {
  if (!currency) {
    return null;
  }
  return Intl.NumberFormat(undefined, {
    style: "currency",
    notation: "compact",
    currency: currency,
    maximumFractionDigits: decimals || 0,
    minimumFractionDigits: decimals || 0,
  }).format(value);
}

function isEmpty(value) {
  return value == null || value === undefined || value.length === 0;
}
export {
  formatValues,
  getDataWithKey,
  isEmpty,
  shuffle,
  getDate,
  formatCurrency,
};
