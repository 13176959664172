import { KEEP_UNUSED_DATA as keepUnusedDataFor } from "constants";
export const liquidityEndpoints = (builder) => {
  return {
    getLiquidityTable: builder.query({
      query: ({ id }) => ({
        url: `fadata/liquidity/${id}/`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
    }),
    updateTransaction: builder.mutation({
      query: ({ id, data }) => ({
        url: `fadata/liquidity/${id}/`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: data,
      }),
    }),
    deleteTransaction: builder.mutation({
      query: ({ id }) => ({
        url: `fadata/liquidity/${id}/`,
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      }),
    }),
    createTransaction: builder.mutation({
      query: ({ data }) => ({
        url: `fadata/liquidity/`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: data,
      }),
    }),
  };
};
