import React from "react";
import PropTypes from "prop-types";
import {
  DataGridPremium,
  useGridApiRef,
  GRID_AGGREGATION_FUNCTIONS,
  nbNO,
} from "@mui/x-data-grid-premium";
import { BasicCustomToolbar } from "./BasicCustomToolbar";
import { CUSTOM_AGGREGATION_FUNCTIONS } from "./utils";
import { useDataGridColumns } from "./hooks/useDataGridColumns";
import { useTranslation } from "react-i18next";
/**
 *
 * @param {object} data
 * @param {boolean} autoHeight
 * @returns {object} DataGridPremium
 */
export const DataGridBasic = ({
  data,
  autoHeight,
  pageSize,
  autoPageSize,
  Title,
  showToolbar,
  onRowClick,
  sx,
}) => {
  const gridRef = useGridApiRef();
  const { i18n } = useTranslation();
  const { processedColumns } = useDataGridColumns(data?.columns);
  if (!Array.isArray(data?.rows) || !Array.isArray(data?.columns)) {
    return null;
  }

  return (
    <div style={{ display: "flex", height: "100%", p: 0, m: 0 }}>
      <div style={{ flexGrow: 1, maxWidth: "100%" }}>
        <DataGridPremium
          localeText={
            i18n.language === "no"
              ? nbNO.components.MuiDataGrid.defaultProps.localeText
              : undefined
          }
          sx={sx}
          apiRef={gridRef}
          density={"compact"}
          aggregationFunctions={{
            ...GRID_AGGREGATION_FUNCTIONS,
            ...CUSTOM_AGGREGATION_FUNCTIONS,
          }}
          pageSize={pageSize}
          autoPageSize={autoPageSize}
          initialState={{
            aggregation: {
              model: data?.columns?.reduce((previous, current) => {
                if (current.aggregation) {
                  return {
                    ...previous,
                    [current.field]: current.aggregation,
                  };
                }
                return { ...previous };
              }, {}),
            },
          }}
          experimentalFeatures={{
            rowGrouping: false,
            aggregation: true,
          }}
          autoHeight={autoHeight}
          hideFooter={true}
          slots={{
            toolbar: showToolbar && BasicCustomToolbar,
          }}
          slotProps={{
            toolbar: {
              Title: Title,
            },
          }}
          onRowClick={onRowClick}
          disableColumnMenu
          columns={processedColumns}
          rows={data?.rows?.map((item, idx) => {
            item.id = idx;
            return item;
          })}
          autosizeOnMount={true}
          autosizeOptions={{ expand: true }}
        ></DataGridPremium>
      </div>
    </div>
  );
};

DataGridBasic.propTypes = {
  data: PropTypes.shape({ columns: PropTypes.array, rows: PropTypes.array }),
};

export default DataGridBasic;
