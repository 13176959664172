import faDataReducer from "./store/reducers/fadatareducer";
import generalReducer from "./store/reducers/general";
import pswmanagerReducer from "./store/reducers/pswmanager";
import faDataSliceReducer from "./store/slices/faDataSlice";
import authReducer from "./store/slices/authSlice";
import userReducer from "./store/slices/userSlice";
import snackbarReducer from "./store/slices/snackbarSlice";
import _ from "lodash";
import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import storage from "redux-persist/lib/storage";
import LogRocket from "logrocket";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { api } from "./api/apiSlice";
import queryTriggerSliceReducer from "store/slices/queryTriggerSlice";
// const persistGeneralConfig = {
//   key: "general",
//   version: 1,
//   storage,
// };
// const persistAuthConfig = {
//   key: "auth",
//   storage,
// };
// const persistDataConfig = {
//   key: "data",
//   version: 1,
//   storage,
// };
// const persistUserStorage = {
//   key: "user",
//   storage,
// };
// const persistedAuthReducer = persistReducer(persistAuthConfig, authReducer);
// const persistedDataReducer = persistReducer(
//   persistDataConfig,
//   faDataSliceReducer
// );
// const persistedUserReducer = persistReducer(persistUserStorage, userReducer);
// const persistedApiReducer = persistReducer(
//   { key: "api", storage },
//   api.reducer
// );

const reducer = {
  data: faDataReducer,
  general: generalReducer,
  pswmanager: pswmanagerReducer,
  faData: faDataSliceReducer,
  queryTriggers: queryTriggerSliceReducer,
  user: userReducer,
  auth: authReducer,
  snackbar: snackbarReducer,
  [api.reducerPath]: api.reducer,
};

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    })
      .concat(
        import.meta.env.MODE !== "production"
          ? [logger]
          : [LogRocket.reduxMiddleware()]
      )
      .concat(api.middleware),
  devTools: import.meta.env.MODE !== "production",
});
// export const persistor = persistStore(store);
