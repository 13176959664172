import React, { useState, useEffect, memo } from "react";

import { useTheme } from "@mui/material/styles";
import Plot from "react-plotly.js";
import * as norwegian from "plotly.js/lib/locales/no";
import * as Plotly from "plotly.js/dist/plotly";
import _ from "lodash";
import Typography from "@mui/material/Typography";
import { Button, Box } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { formatValues } from "../../utils/functions";
//MATERIAL UI ICONS
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { DataGridBasic } from "../Tables/DataGridTable";
import { useExtendedTranslation } from "hooks/useExtendedTranslation";
import { PLOTLY_ICONS } from "assets/Icons";
import { setBackground } from "./utils";
export const PlotlyChartDrilldown = memo(({ data, light, onClick }) => {
  const plotlyRef = React.useRef();
  const theme = useTheme();
  const [chartData, setData] = useState(null);
  const [tableToggle, setTableToggle] = useState(false);
  const { t, i18n } = useExtendedTranslation();

  const handlePlotlyOnClick = (e, x) => {
    const index = e.points[0].pointIndex;
    if (Number.isInteger(index)) {
      if (onClick) {
        onClick(e?.points[0].pointIndex, chartData);
      }
      navigator(chartData, e?.points[0].pointIndex);
      return;
    }
    if (onClick) {
      onClick(e?.points[0].i, chartData);
    }
    navigator(chartData, e?.points[0].i);
  };
  const reset = () => {
    setData(data);
  };
  const handleRowClick = (row) => {
    if (onClick) {
      onClick(row, chartData);
    }
    navigator(chartData, row.id);
  };
  const navigator = (input, index) => {
    if (input?.drilldown?.length === 0) {
      reset();
      return;
    }
    if (!input.drilldown) {
      reset();
      return;
    }
    setData(input.drilldown[index]);
  };

  useEffect(() => {
    if (data) {
      setData(data);
    }
  }, [data]);
  const layout = _.defaultsDeep(
    {
      title: {
        text: t(chartData?.chart_config?.title),
        font: {
          family: "Rubik, Calibri, sans-serif",
          size: 24,
          color: light ? "white" : theme.palette.primary.dark,
        },
      },
      font: {
        family: "Rubik, Calibri, sans-serif",
      },
      legend: {
        font: {
          family: "Calibri",
          size: 16,
          color: light ? "#ffffff" : theme.palette.text.secondary,
        },
      },

      autosize: true,
      plot_bgcolor: "transparent",
      paper_bgcolor: "transparent",
      xaxis: {
        showgrid: false,
        color: light ? "#ffffff" : theme.palette.text.secondary,
        gridcolor: light ? "666666" : theme.palette.secondary.dark,
        zerolinecolor: light ? "e2e2e2" : theme.palette.primary.dark,
        linecolor: "#f3f3f3",
        automargin: true,
      },
      yaxis: {
        showgrid: false,
        color: light ? "#ffffff" : theme.palette.text.secondary,
        gridcolor: light ? "666666" : theme.palette.secondary.dark,
        zerolinecolor: light ? "e2e2e2" : theme.palette.primary.dark,
        linecolor: "#f3f3f3",
        automargin: true,
      },
      textfont: {
        color: "#ffffff",
      },
      locale: i18n.language,
      modebar: {
        activecolor: theme.palette.primary.light,
        bgcolor: "transparent",
        color:
          theme.palette.mode === "dark"
            ? "#ffffff"
            : theme.palette.primary.main,
      },
    },
    chartData?.chart_config?.layout
  );
  const config = {
    displaylogo: false,
    locale: i18n.language,
    locales: { no: norwegian },
    responsive: true,
    displayModeBar: true,
    toImageButtonOptions: {
      format: "jpeg",
      filename: chartData?.chart_config?.title,
      setBackground: setBackground,
    },
    modeBarButtonsToRemove: [
      "pan2d",
      "select2d",
      "lasso2d",
      "resetScale2d",
      "autoScale2d",
      "zoomOut2d",
      "zoomIn2d",
      "zoom2d",
      "toImage",
    ],
    modeBarButtonsToAdd: [
      {
        name: "downloadImageAsJpeg",
        title: "Download as JPEG",
        icon: PLOTLY_ICONS.downloadAsJPEG(1000, 1000),
        click: function (gd) {
          Plotly?.downloadImage(gd, {
            setBackground: setBackground,
            format: "jpeg",
            width: 1080,
            height: 720,
            filename: t(chartData?.chart_config?.title),
          });
        },
      },
      {
        name: "resetData",
        title: "Reset",
        icon: {
          width: 22,
          height: 22,
          path: PLOTLY_ICONS.reset().path,
        },
        click: function () {
          reset();
        },
      },
    ],
  };

  const plotlyStyles = {
    width: "100%",
    height: "100%",
    minHeight: "500px",
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        "& .slice:hover": {
          cursor: "pointer",
        },
      }}
    >
      <Plot
        ref={plotlyRef}
        onClick={handlePlotlyOnClick}
        data={
          Array.isArray(chartData?.chart?.chart_data)
            ? chartData?.chart?.chart_data
            : [chartData?.chart?.chart_data]
        }
        layout={layout}
        style={plotlyStyles}
        config={config}
      ></Plot>
      <Button onClick={() => setTableToggle(!tableToggle)}>
        <Typography color="textPrimary">{t("general.table")}</Typography>
        {!tableToggle ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
      </Button>
      <Collapse in={tableToggle}>
        <Box sx={{ p: 2, height: "500px" }}>
          <DataGridBasic
            autosize={true}
            onRowClick={handleRowClick}
            data={{
              columns: [
                {
                  field: "name",
                  type: "text",
                  headerName: t("general.name"),
                  flex: 1,
                },
                {
                  field: "marketValue",
                  headerName: t("financeTerms.marketValue"),
                  aggregation: "sum",
                  flex: 1,
                  type: "number",
                  valueFormatter: (params) =>
                    formatValues(params.value, "millions"),
                },
              ],
              rows: chartData?.table?.map((item, index) => {
                item.id = index;
                return item;
              }),
            }}
          ></DataGridBasic>
        </Box>
      </Collapse>
    </Box>
  );
});

export default PlotlyChartDrilldown;
