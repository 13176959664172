import { createSlice, nanoid } from "@reduxjs/toolkit";

const initialState = {
  triggers: {}, // Object to hold trigger states keyed by unique identifiers
};

const queryTriggersSlice = createSlice({
  name: "queryTriggers",
  initialState,
  reducers: {
    triggerLazyQuery: {
      reducer: (state, action) => {
        const { key, value } = action.payload;
        state.triggers[key] = value;
      },
      // Prepare action with a payload containing a key and a unique value (e.g., timestamp, counter, or UUID)
      prepare: (key, value = nanoid()) => ({ payload: { key, value } }),
    },
    // Optionally, add other reducers for resetting or removing triggers
  },
});

export const { triggerLazyQuery } = queryTriggersSlice.actions;

export default queryTriggersSlice.reducer;
