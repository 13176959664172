import _ from "lodash";

export const DEFAULTCOLORS = {
  primary: "#ADA59D", //"#ADA59D",
  secondary: "#F3E8DE", //"#F3E8DE"
  tertiary: "#023D32", //"#023D32"
  info: "#EEECEA",
  success: "#0000d4",
  warning: "#ff9800",
  error: "#fe2e0e",
  dark: "#000",
  light: "#fff",
  mode: "light",
};
export const YS_COLORS = {
  primary: "#397F77", //"#ADA59D",
  secondary: "#64BAB0", //"#F3E8DE"
  tertiary: "#292929", //"#023D32"
  info: "#E3E3E3",
  success: "#0000d4",
  warning: "#ff9800",
  error: "#fe2e0e",
  dark: "#000",
  light: "#fff",
  mode: "light",
};
/**
 * Pads a string with zeros to a specified length.
 *
 * @param {string} str - The string to pad with zeros.
 * @param {number} [len=2] - The desired length of the padded string. Defaults to 2.
 * @returns {string} The padded string.
 */
function rgbToHsl(r, g, b) {
  (r /= 255), (g /= 255), (b /= 255);
  let max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return [h, s, l];
}
function hue2rgb(p, q, t) {
  if (t < 0) t += 1;
  if (t > 1) t -= 1;
  if (t < 1 / 6) return p + (q - p) * 6 * t;
  if (t < 1 / 2) return q;
  if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
  return p;
}
function hslToRgb(h, s, l) {
  let r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    let p = 2 * l - q;
    h *= 360; // Convert hue to degrees
    h /= 360; // Normalize hue
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

function rgbToHex(r, g, b) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function lighten(color, weight) {
  let [r, g, b] = color.match(/\w\w/g).map((c) => parseInt(c, 16));
  let [h, s, l] = rgbToHsl(r, g, b);
  l = Math.min(1, l + weight); // Ensure lightness doesn't exceed 1
  [r, g, b] = hslToRgb(h, s, l);
  return rgbToHex(r, g, b);
}

function darken(color, weight) {
  let [r, g, b] = color.match(/\w\w/g).map((c) => parseInt(c, 16));
  let [h, s, l] = rgbToHsl(r, g, b);
  l = Math.max(0, l - weight); // Ensure lightness doesn't go below 0
  [r, g, b] = hslToRgb(h, s, l);
  return rgbToHex(r, g, b);
}
function generateShades(baseColor) {
  let shades = {};
  const shadeWeights = [0.4, 0.3, 0.2, 0.1, 0.05]; // Example weights for shade generation

  // Generate lighter shades
  shadeWeights.forEach((weight, index) => {
    shades[`${100 * (index + 1)}`] = lighten(baseColor, weight);
  });

  // Add the base color as 500
  shades["500"] = baseColor;

  // Generate darker shades
  shadeWeights.reverse().forEach((weight, index) => {
    shades[`${600 + 100 * index}`] = darken(baseColor, weight);
  });

  return shades;
}
export const YS_DEFAULT_THEME = (input) => {
  let colors = DEFAULTCOLORS;
  if (input) {
    colors = _.merge({ ...DEFAULTCOLORS }, input);
  }
  return {
    palette: {
      primary: { main: colors.primary },
      secondary: {
        main: colors.secondary,
      },
      error: {
        main: colors.error,
      },
      warning: {
        main: colors.warning,
      },
      success: {
        main: colors.success,
      },
      primaryShades: generateShades(colors.primary),
      secondaryShades: generateShades(colors.secondary),
      infoShades: generateShades(colors.info),
      info: { main: colors.info },
      mode: colors.mode ? colors.mode : "light",
      gradients: {
        primary: `linear-gradient(to right, ${colors.primary} , #ffffff)`,
        secondary: "linear-gradient(to right,#136f63 ,#041b15)",
        lightdark: {
          right: `linear-gradient(to right, ${colors.secondary} , ${colors.info})`,
          left: `linear-gradient(to left, ${colors.secondary} , ${colors.info})`,
          up: `linear-gradient(180deg, ${colors.info} , ${colors.secondary})`,
          down: `linear-gradient(180deg, ${colors.info} , ${colors.secondary})`,
        },
        light: `linear-gradient(180deg, ${colors.info} , ${colors.light})`,

        darkfade: {
          right: "linear-gradient(to right, #041b15, #136f63)",
          left: `linear-gradient(to left, ${colors.secondary} , ${colors.info})`,
          up: "linear-gradient(0deg, #041b15, #136f63)",
          down: "linear-gradient(180deg, #041b15, #136f63)",
        },
      },
      charts: {
        main: null,
        neutral: ["#20639B", "#56534e"],
        random: "shuffle(colors.chartOne)",
        pos: colors.success,
        neg: colors.error,
        plain: ["#02738C", "#00286C"],
        combo: ["#02738C", "#00286C", "#FEE12B"],
      },
    },
    components: {
      MuiDataGrid: {
        styleOverrides: {
          columnHeaders: {
            height: "auto !important",
            maxHeight: "200px !important",
          },
          columnHeader: {
            height: "auto !important",
          },
          columnHeaderTitle: {
            whiteSpace: "normal",
            overflow: "visible",
            lineHeight: "normal",
            height: "auto",
          },
          aggregationColumnHeader: {
            height: "auto",
            paddingBottom: "15px !important",
          },
        },
      },
    },
    typography: {
      fontFamily: [
        "Calibri",
        "Rubik",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  };
};
export const YS_DEFAULT_DARK_THEME = {
  palette: {
    primary: {},
    secondary: {},
    error: {},
    success: {},
    info: {},
    mode: "dark",
  },
};
