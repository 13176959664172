import { KEEP_UNUSED_DATA as keepUnusedDataFor } from "constants";
export const allocationsEndpoints = (builder) => {
  return {
    getAllocationsDynamicPieCharts: builder.query({
      query: ({ endDate, startDate, filters }) => {
        const params = new URLSearchParams();

        if (startDate) params.append("startDate", startDate);
        if (endDate) params.append("endDate", endDate);
        if (filters) params.append("filters", filters.join(","));
        return {
          url: `fadata/standardComponents/allocationDynamicPieCharts/?${params}`,
          method: "GET",
          headers: { "Content-Type": "application/json" },
        };
      },
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "ALLOCATIONS_DYNAMIC_PIE_CHART" }],
    }),
  };
};
