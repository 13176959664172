import React from "react";
import { Controller } from "react-hook-form";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { format, parseISO } from "date-fns";

export const FormInputDate = ({
  name,
  control,
  label,
  variant,
  maxVal,
  callback,
  minVal,
  defaultValue,
  isRequired,
  disabled,
  size,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        required: {
          value: Boolean(isRequired),
          message: "This field is required",
        },
        validate: (input) => {
          return input == "Invalid Date"
            ? "Please insert a valid date"
            : undefined;
        },
      }}
      render={({ field, fieldState: { error } }) => {
        const { onChange, onBlur, value } = field;
        const handleBlur = (input) => {
          let date = null;
          try {
            date = format(new Date(input?.target?.value), "yyyy-MM-dd");
            onChange(date);
          } catch (error) {
            onChange("Invalid Date");
          }
          if (callback) {
            callback(date);
          }

          onBlur(input);
        };
        const handleChange = (e, data) => {
          if (e == "Invalid Date") {
            onChange(e);
            return;
          }
          onChange(data || null);
        };
        const handleAccept = (e) => {
          let date = e;
          try {
            date = format(new Date(e), "yyyy-MM-dd");
          } catch (error) {
            date = "Invalid Date";
          }
          if (callback) {
            callback(date);
          }
          onChange(date);
        };
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={["year", "month", "day"]}
              size="small"
              format="yyyy-MM-dd"
              mask="____-__-__"
              maxDate={maxVal ? parseISO(maxVal) : undefined}
              minDate={minVal ? parseISO(minVal) : undefined}
              label={label}
              disabled={disabled}
              {...field}
              value={parseISO(value)}
              onChange={handleChange}
              onAccept={handleAccept}
              inputRef={field.ref}
              slotProps={{
                textField: {
                  onBlur: handleBlur,
                  variant: variant ? variant : "standard",
                  size: size || "medium",
                  error: !!error,
                  helperText: error?.message,
                  required: isRequired,
                },
              }}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
};

export default FormInputDate;
