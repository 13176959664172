import React, { useState } from "react";

import { useSelector } from "react-redux";
import PropTypes from "prop-types";

// MUI Styles

// MUI Core
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";

//YS COMPONENTS IMPORT
import KeyDataHeader from "../../../components/KeyDataHeader";
import PlotlyChart from "../../../components/PlotlyChart/PlotlyChart";
import YSSwitchPlain from "../../../components/YSSwitchPlain";

import AttributionPlots from "./AttributionPlots";

import { useGetNewAttributionQuery } from "api/apiSlice";
import RTKQueryWrapper from "components/DataFetchWrapper/RTKQueryWrapper";
import { useParams } from "react-router-dom";
import { Autocomplete, TextField } from "@mui/material";
function AttributionNode() {
  const { portfolioId } = useParams();
  const endDate = useSelector((state) => state.user.endDate);
  const newAttributionQuery = useGetNewAttributionQuery(
    {
      endDate: endDate,
      portfolioId: Number(portfolioId),
    },
    {
      skip: !portfolioId, // The query will be skipped if portfolioId is null or undefined
    }
  );
  const [attributionEffectsState, setAttributionEffectsState] = useState({
    label: "All",
    value: "all",
    id: 0,
  });
  const [allocationChartState, setAllocationChartState] = useState("absolute");

  const handleAllocationToggle = () => {
    setAllocationChartState(
      allocationChartState === "absolute" ? "relative" : "absolute"
    );
  };
  return (
    <RTKQueryWrapper
      query={newAttributionQuery}
      LoadingComponent={CircularProgress}
    >
      {(data) => {
        return (
          <>
            {" "}
            <KeyDataHeader
              data={data?.summary}
              size={"medium"}
              light={true}
            ></KeyDataHeader>
            <Grid
              container
              justifyContent="center"
              alignItems={"center"}
              height={"100%"}
            >
              <Grid item xs={12} lg={6} minHeight={"400px"}>
                <Card elevated="true" sx={{ margin: 1, height: "100%" }}>
                  <PlotlyChart
                    {...data?.attribution_total?.plotly}
                    light={false}
                  ></PlotlyChart>
                </Card>
              </Grid>
              <Grid item xs={12} lg={6} minHeight={"400px"}>
                <Card elevated="true" sx={{ margin: 1, height: "100%" }}>
                  <AttributionPlots
                    data={data?.contribution_data?.plotly}
                    light={false}
                  ></AttributionPlots>
                </Card>
              </Grid>
              <Grid item xs={12} lg={6} minHeight={"400px"}>
                <Card elevated="true" sx={{ margin: 1, minHeight: "100%" }}>
                  <PlotlyChart
                    {...data?.attribution_effects?.plotly[
                      attributionEffectsState?.value
                    ]}
                    light={false}
                  ></PlotlyChart>

                  <CardActions>
                    <Autocomplete
                      sx={{ maxWidth: 300 }}
                      size="small"
                      variant="filled"
                      value={attributionEffectsState}
                      isOptionEqualToValue={(opt, val) =>
                        opt?.value === val?.value
                      }
                      fullWidth
                      getOptionLabel={(option) => {
                        return option.label ?? null;
                      }}
                      options={[
                        { value: "all", label: "All", id: 0 },
                        { value: "top_bottom", label: "Top Bottom", id: 1 },
                        {
                          value: "per_asset_class",
                          label: "Asset class",
                          id: 2,
                        },
                      ]}
                      onChange={(event, newValue) => {
                        if (newValue) setAttributionEffectsState(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Group" />
                      )}
                    />
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} lg={6} minHeight={"400px"}>
                <Card elevated="true" sx={{ margin: 1, minHeight: "100%" }}>
                  <PlotlyChart
                    {...data?.allocation_data.plotly[allocationChartState]}
                    light={false}
                  ></PlotlyChart>

                  <CardActions>
                    <YSSwitchPlain
                      onClick={handleAllocationToggle}
                      isChecked={allocationChartState === "relative"}
                      leftValue="absolute"
                      rightValue="relative"
                    ></YSSwitchPlain>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </>
        );
      }}
    </RTKQueryWrapper>
  );
}

AttributionNode.propTypes = {};

export default AttributionNode;
