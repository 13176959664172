import React from "react";
import { parseISO, isBefore, isAfter, format } from "date-fns";
import _ from "lodash";
import { useMatch, useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Button, Box } from "@mui/material";
import { DatepickerYS } from "../DatePickerYS";
import {
  month_to_date,
  year_to_date,
  quarterly_to_date,
  get_end_of_month,
} from "../DatePickerYS/utils";
import { api } from "../../api/apiSlice";
import {
  setStartDate,
  setEndDate,
  setCustomStartDate,
  setCustomEndDate,
} from "store/slices/userSlice";
import { FA_DATA_TAGS } from "api/tags";
import { triggerLazyQuery } from "store/slices/queryTriggerSlice";
import { useTranslation } from "react-i18next";
const DatePickerContainer = () => {
  const dataLoading = useSelector((state) => {
    return Object.values(state.api.queries).some((query) => query.pending);
  });
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const endDate = useSelector((state) => state.user.endDate);
  const customStartDate = useSelector((state) => state.user.customStartDate);
  const customEndDate = useSelector((state) => state.user.customEndDate);
  const { max_date_calculation_basis } = useSelector(
    (state) => state.user?.chosen_client?.settings
  );
  const minDate = useSelector((state) => state.user.minDate);
  const dispatch = useDispatch();
  const transactionMatch = useMatch("app/transactions/*");
  const performanceMatch = useMatch("app/performance/*");
  const handleStartDatePicker = (date) => {
    if (isAfter(parseISO(date), parseISO(customEndDate))) {
      dispatch(setCustomEndDate(date));
      dispatch(setEndDate(date));
    }
    dispatch(setStartDate(date));
    dispatch(setCustomStartDate(date));
  };

  const handleEndDatePicker = (date) => {
    if (max_date_calculation_basis === "end_last_month") {
      const eOM = get_end_of_month(date);
      date = format(eOM, "yyyy-MM-dd");
    }
    if (isBefore(parseISO(date), parseISO(customStartDate))) {
      dispatch(setCustomStartDate(date));
    }
    dispatch(setEndDate(date));
    dispatch(setCustomEndDate(date));
    if (!(transactionMatch || performanceMatch)) {
      api.util.invalidateTags(FA_DATA_TAGS);
    }
  };

  const onError = () => {
    return null;
  };
  const since_inception_date = () => {
    return parseISO(minDate);
  };
  const globalDatePresets = [
    {
      id: "ITD",
      text: t("datepickers.presets.itd"),
      func: since_inception_date,
    },
    {
      id: "MTD",
      text: t("datepickers.presets.mtd"),
      func: month_to_date,
    },
    {
      id: "YTD",
      text: t("datepickers.presets.ytd"),
      func: year_to_date,
    },

    {
      id: "QTD",
      text: t("datepickers.presets.qtd"),
      func: quarterly_to_date,
    },
  ];
  const handleCalculate = _.throttle(() => {
    if (dataLoading) {
      alert("Not so fast, let me think a little");
    } else {
      const key = pathname.split("/").pop() || pathname; // Fallback to full pathname if needed
      dispatch(triggerLazyQuery(key));
      dispatch(api.util.invalidateTags(FA_DATA_TAGS));
    }
  }, 500);
  return (
    <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "left" }}>
      {transactionMatch || performanceMatch ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <DatepickerYS
            disableCalenderIcon={true}
            name={"startDate"}
            id={"start"}
            callback={handleStartDatePicker}
            date={customStartDate}
            presets={globalDatePresets}
            presetDateValue={customEndDate}
            onPresetClick={handleStartDatePicker}
            onError={onError}
          />
          <Button
            disabled={dataLoading}
            sx={{ mx: 2 }}
            size="small"
            variant="contained"
            color="info"
            onClick={handleCalculate}
          >
            {t("general.calculate")}
          </Button>
          <DatepickerYS
            disableCalenderIcon={true}
            name={"endDate"}
            id={"end"}
            callback={handleEndDatePicker}
            date={customEndDate}
            presetDateValue={customEndDate}
          />
        </Box>
      ) : (
        <DatepickerYS
          name={"endDate"}
          date={endDate}
          disableCalenderIcon={false}
          callback={handleEndDatePicker}
        />
      )}
    </Box>
  );
};

DatePickerContainer.propTypes = {};

export default DatePickerContainer;
