import React from "react";
import { useTheme } from "@mui/material/styles";
import Plot from "react-plotly.js";
import _ from "lodash";
import { DEFAULT_SELECTOR_OPTIONS } from "./constants";
import useExtendedTranslation from "hooks/useExtendedTranslation";
import { PLOTLY_ICONS } from "assets/Icons";
import * as norwegian from "plotly.js/lib/locales/no";
import * as Plotly from "plotly.js/dist/plotly";
import { setBackground } from "./utils";
/**
 * @description This is generic ReactPlotly chart with connected styles from material design
 * @param {chart_data, chart_config, light, onClick, isLoading} = props;
 * @returns React.JSXElement
 */
export const PlotlyChart = ({
  chart_data,
  chart_config,
  light,
  onClick,
  minHeightChart,
  customSelectorOptions,
  isRangeSlider,
}) => {
  const { t, i18n } = useExtendedTranslation();
  const plotlyRef = React.useRef();
  const theme = useTheme();
  const selectorOptions = customSelectorOptions || DEFAULT_SELECTOR_OPTIONS;
  const layout = _.defaultsDeep(
    {
      title: {
        text: t(chart_config?.title),
        font: {
          family: "Rubik, Calibri, sans-serif",
          size: 20,
          color: light ? "white" : theme.palette.text.primary,
        },
      },
      font: {
        family: "Rubik, Calibri, sans-serif",
      },
      legend: {
        font: {
          family: "Calibri, sans-serif",
          size: 16,
          color: light ? "#ffffff" : theme.palette.text.secondary,
        },
      },
      dragmode: false,
      autosize: true,
      plot_bgcolor: "transparent",
      paper_bgcolor: "transparent",
      xaxis: {
        showgrid: false,
        color: light ? "#ffffff" : theme.palette.text.secondary,
        gridcolor: light ? "666666" : theme.palette.secondary.dark,
        zerolinecolor: light ? "e2e2e2" : theme.palette.primary.dark,
        linecolor: "#f3f3f3",
        rangeselector: isRangeSlider ? selectorOptions : {},
        rangeslider: { visible: isRangeSlider || false },
        automargin: true,
        title: {
          font: {
            family: "Calibri, sans-serif",
            size: 20,
            color: light ? "#ffffff" : theme.palette.text.secondary,
          },
        },
        tickfont: {
          family: "sans-serif",
          size: 14,
          color: light ? "#ffffff" : theme.palette.text.secondary,
        },
      },
      yaxis: {
        showgrid: false,
        color: light ? "#ffffff" : theme.palette.text.secondary,
        gridcolor: light ? "666666" : theme.palette.secondary.dark,
        zerolinecolor: light ? "e2e2e2" : theme.palette.primary.dark,
        linecolor: "#f3f3f3",
        automargin: true,
        tickfont: {
          family: "sans-serif",
          size: 14,
          color: light ? "#ffffff" : theme.palette.text.secondary,
        },
        title: {
          font: {
            family: "Calibri, sans-serif",
            size: 20,
            color: light ? "#ffffff" : theme.palette.text.secondary,
          },
        },
      },

      locale: i18n.language,

      modebar: {
        activecolor: theme.palette.primary.light,
        bgcolor: "transparent",
        color:
          theme.palette.mode === "dark"
            ? "#ffffff"
            : theme.palette.primary.main,
      },
    },
    chart_config?.layout
  );
  const config = {
    displaylogo: false,
    locale: i18n.language,
    locales: { no: norwegian },
    displayModeBar: true,
    toImageButtonOptions: {
      format: "jpeg",
      filename: chart_config?.title,
      setBackground: setBackground,
    },
    modeBarButtonsToRemove: [
      "pan2d",
      "select2d",
      "lasso2d",
      "resetScale2d",
      "autoScale2d",
      "zoomOut2d",
      "zoomIn2d",
      "zoom2d",
      "toImage",
    ],
    modeBarButtonsToAdd: [
      {
        name: "downloadImageAsJpeg",
        title: "Download as JPEG",
        icon: PLOTLY_ICONS.downloadAsJPEG(1000, 1000),
        click: function (gd) {
          Plotly?.downloadImage(gd, {
            setBackground: setBackground,
            format: "jpeg",
            width: 1080,
            height: 720,
            filename: t(chart_config?.title),
          });
        },
      },
    ],
  };

  const plotlyStyles = {
    width: "100%",
    height: "100%",
    minHeight: minHeightChart || "500px",
  };

  return (
    <Plot
      id="plotly-chart-without-controls"
      ref={plotlyRef}
      onClick={onClick}
      data={Array.isArray(chart_data) ? chart_data : [chart_data]}
      layout={{ ...layout, dragmode: "pan" }}
      style={plotlyStyles}
      config={config}
    ></Plot>
  );
};

export default PlotlyChart;
