import React from "react";
import { Box } from "@mui/material";
import { Tabs } from "components/Tabs/Tabs";
import { Outlet, useLocation } from "react-router-dom";

const HealthCheckLayout = () => {
  const location = useLocation();

  const tabs = [
    { key: "1", label: "Dashboard", value: "dashboard" },
    { key: "2", label: "TWR Check", value: "timeseries" },
  ];

  const currentTab = tabs.findIndex(tab => location.pathname.includes(tab.value));

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Tabs
        id={"HealthCheck"}
        options={tabs.map(tab => ({
          key: tab.key,
          label: tab.label,
          value: `/app/fa_doctor/${tab.value}`
        }))}
        value={currentTab !== -1 ? `/app/fa_doctor/${tabs[currentTab].value}` : ""}
      />
      <Outlet />
    </Box>
  );
};

export default HealthCheckLayout;
