import { FA_DATA_TAGS } from "./tags";
export const clientEndpoints = (builder) => {
  return {
    changeClient: builder.mutation({
      query: ({ new_client_id }) => ({
        url: `fadata/change_chosen_client/${new_client_id}/`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      }),
      providesTags: [{ type: "ClientInfo", id: "CLIENT_CHANGE" }],
      invalidatesTags: [
        ...FA_DATA_TAGS,
        { type: "ClientInfo", id: "USER_SUBSCRIPTION" },
      ],
    }),
    getQandA: builder.query({
      query: () => ({
        url: `fadata/qanda_list/`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      providesTags: [{ type: "ClientInfo", id: "QUESTIONS_AND_ANSWERS" }],
    }),
    createQandA: builder.mutation({
      query: (data) => ({
        url: `fadata/qanda_detail/`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: data,
      }),
      invalidatesTags: [{ type: "ClientInfo", id: "QUESTIONS_AND_ANSWERS" }],
    }),
    updateQandA: builder.mutation({
      query: ({ data, id }) => ({
        url: `fadata/qanda_detail/${id}/`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: data,
      }),
      invalidatesTags: [{ type: "ClientInfo", id: "QUESTIONS_AND_ANSWERS" }],
    }),
    deleteQandA: builder.mutation({
      query: ({ id }) => ({
        url: `fadata/qanda_detail/${id}/`,
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      }),
      invalidatesTags: [{ type: "ClientInfo", id: "QUESTIONS_AND_ANSWERS" }],
    }),
    updateClientSettings: builder.mutation({
      query: ({ id, data }) => ({
        url: `fadata/client_settings/${id}/`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: data,
      }),
      invalidatesTags: [{ type: "ClientInfo", id: "CLIENT_SETTINGS" }],
    }),
  };
};
