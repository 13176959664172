import React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";

import ListItemIcon from "@mui/material/ListItemIcon";
import Box from "@mui/material/Box";
import ListItemText from "@mui/material/ListItemText";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button } from "@mui/material";
function DropDownMenu({
  menuItems,
  menuId,
  icon,
  placeholder,
  onSelect,
  dense,
  menuSx,
  buttonSx,
  PaperSx,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleItemActions = (callback, item) => {
    if (typeof callback === "function") {
      callback();
    }
    if (typeof onSelect === "function") {
      onSelect(item);
    }
    handleClose();
  };

  return menuItems ? (
    <Box>
      {!icon ? (
        <Button
          sx={buttonSx}
          id={"drop-down-button"}
          aria-controls={anchorEl ? menuId : undefined}
          aria-haspopup="true"
          aria-expanded={anchorEl ? "true" : undefined}
          variant="contained"
          disabled={menuItems.length < 1}
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          {placeholder || "Menu"}
        </Button>
      ) : (
        <IconButton
          sx={buttonSx}
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={handleClick}
          size="large"
        >
          {icon}
        </IconButton>
      )}

      <Menu
        sx={menuSx}
        slotProps={{ paper: { sx: PaperSx } }}
        id={menuId}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        {menuItems.map((item, idx) => {
          if (!item) {
            return null;
          }
          if (React.isValidElement(item)) {
            return item;
          }

          return (
            <MenuItem
              key={idx}
              onClick={() => handleItemActions(item.onClick, item)}
              dense={dense}
              {...item}
            >
              {Boolean(item?.icon) && <ListItemIcon>{item?.icon}</ListItemIcon>}
              <ListItemText primary={item.text} />
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  ) : null;
}
DropDownMenu.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.node])
  ).isRequired,
  icon: PropTypes.node,
  placeholder: PropTypes.string,
};
export default DropDownMenu;
