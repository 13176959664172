import React, { useState, Fragment } from "react";
import { v4 as uuid } from "uuid";
import Downloader from "../components/Downloader";
import DownloaderNew from "../components/Downloader/DownloaderNew";
import axios from "axios";
import { CircularProgress } from "@mui/material";

export const useFileDownloader = () => {
  const [file, setFile] = useState(null);
  const download = (file) => setFile({ ...file, downloadId: uuid() });
  const remove = () => setFile(null);

  return [
    (e) => download(e),
    file && <Downloader file={file} remove={() => remove()} />,
  ];
};

export const useFileDownload = () => {
  const [downloadStatus, setDownloadStatus] = useState({
    progress: 0,
    loaded: 0,
    total: undefined,
    completed: false,
    lengthComputable: false,
  });
  const onDownloadProgress = (progressEvent) => {
    const { loaded, lengthComputable, total } = progressEvent;
    if (lengthComputable) {
      const percentage = Math.floor((loaded * 100) / total);

      setDownloadStatus({
        progress: percentage,
        loaded: loaded,
        total,
        completed: percentage === 100,
        lengthComputable: lengthComputable,
      });
    }
  };
  const onDownload = (method, url, params, tooltipText) => {
    axios({
      method: method,
      baseURL: url,
      url: "",
      params: params,
      onDownloadProgress: onDownloadProgress,
    })
      .then((response) => {
        console.log(response);
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data], {
            type: response.headers["content-type"],
          })
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", tooltipText);
        document.body.appendChild(link);
        link.click();

        setDownloadStatus((status) => ({
          ...status,
          completed: true,
        }));

        setTimeout(() => {
          setDownloadStatus({
            progress: 0,
            loaded: 0,
            total: undefined,
            completed: false,
            lengthComputable: false,
          });
        }, 4000);
      })
      .catch((e) => {
        console.error("Error", e);
      });
  };
  function DownloadStatusComponent(props) {
    return (
      <Fragment>
        {props.lengthComputable && (
          <CircularProgress
            variant="determinate"
            value={props?.progress}
          ></CircularProgress>
        )}
        {props.children}
      </Fragment>
    );
  }
  return {
    onDownload: (method, url, params, tooltipText) =>
      onDownload(method, url, params, tooltipText),
    DownloadStatus: !downloadStatus.completed ? null : (
      <DownloadStatusComponent {...downloadStatus} />
    ),
    status: downloadStatus,
  };
};
export const useExcelFileDownloader = () => {
  const [data, setData] = useState(null);
  const download = (input) => setData({ ...input, downloadId: uuid() });
  const remove = () => setData(null);

  return {
    download: (e) => download(e),
    downloadComponent: data ? (
      <DownloaderNew data={data} remove={() => remove()} />
    ) : null,
  };
};

export default useFileDownloader;
