import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";
//MATERIAL UI IMPORTS
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
//YS ACTIONS IMPORTS
import { usePasswordResetMutation } from "api/apiSlice";
import { useTranslation } from "react-i18next";
const initialState = {
  email: "",
};

const ForgottenPassword = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset: formReset,
    formState: { errors },
  } = useForm({ defaultValues: initialState });
  const [passwordReset, { isLoading, isSuccess, reset }] =
    usePasswordResetMutation();
  function onSubmit(e) {
    passwordReset(e);
  }
  const handleToggle = () => {
    reset();
  };
  React.useEffect(() => {
    formReset();
  }, [formReset, isSuccess]);
  return (
    <Fragment>
      <Avatar>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5" color="inherit">
        {isSuccess ? t("loginPage.emailSendt") : t("loginPage.forgotPassword")}
      </Typography>
      {!isSuccess ? (
        <React.Fragment>
          <Typography variant="h6" color="inherit">
            {t("loginPage.forgotPasswordDescription")}
          </Typography>
          <Box
            onSubmit={handleSubmit(onSubmit)}
            component={"form"}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <TextField
              label={t("loginPage.email")}
              required
              fullWidth
              autoComplete="email"
              margin="normal"
              variant="outlined"
              {...register("email", {
                required: t("loginPage.emailRequired"),
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: t("loginPage.emailInvalidMessage"),
                },
              })}
              error={!!errors.email}
              helperText={errors.email ? errors.email.message : ""}
            />
            <Button
              type="submit"
              fullWidth
              endIcon={isLoading ? <CircularProgress size={20} /> : null}
              variant="contained"
              color="secondary"
            >
              <Typography variant="button">
                {t("loginPage.requestNewPassword")}
              </Typography>
            </Button>
          </Box>
        </React.Fragment>
      ) : (
        <Fragment>
          <Typography variant="h6">{t("loginPage.instructions")}</Typography>
          <Divider variant="middle" />
          <Button
            sx={{ mt: 2 }}
            type="button"
            variant="contained"
            color="secondary"
            onClick={handleToggle}
          >
            {t("loginPage.retry")}
          </Button>
        </Fragment>
      )}
      <Typography sx={{ mt: 3 }} variant={"body2"}>
        <RouterLink to={`/login`}>{t("loginPage.returnToLogin")}</RouterLink>
      </Typography>
    </Fragment>
  );
};
export default ForgottenPassword;
