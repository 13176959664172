import React from "react";

//REACT ROUTER IMPORTS

//MATERIAL UI IMPORTS
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

//YS ACTIONS IMPORTS

//YS COMPONENTS IMPORTS
import ParticlesComp from "components/ParticlesComp";
import { Outlet } from "react-router";
import { Copyright } from "components/CopyRight";

const LoginLayout = () => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: { xs: "1fr" },
        height: "100vh",
        width: "100%",
        alignItems: "center",
        justifyItems: "center",
      }}
    >
      <Box sx={{ height: "100%", width: "100%" }}>
        <ParticlesComp />
      </Box>

      <Paper
        sx={{
          width: { xs: "80%", md: "600px", lg: "600px" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 2,
          boxShadow: (theme) =>
            "0px 0px 15px 2px " + theme.palette.primary.main,
          borderRadius: 1,
          position: "relative",
          zIndex: 4000,
        }}
      >
        <Outlet></Outlet>
      </Paper>

      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <Copyright />
      </Box>
    </Box>
  );
};
export default LoginLayout;
