import React from "react";
import Box from "@mui/material/Box";
import { DataGridBasic } from "components/Tables/DataGridTable";
import { useTheme } from "@mui/material/styles";
const Accounts = ({ comparator, accounts, selected, filterValue }) => {
  const { rows, columns } = accounts;
  const theme = useTheme();
  // Base heights in pixels
  const headerHeight = 56; // Adjust based on your header's height
  const rowHeight = 52; // Adjust based on your row's height
  const maxHeight = 500; // Minimum height for the grid
  const padding = theme.spacing(1); // Assuming you want some padding based on your theme

  // Filter rows based on selection
  const filteredRows = rows?.filter(
    (item) => (selected ? item[filterValue] === comparator : true),
    []
  );

  // Calculate dynamic height
  let dynamicHeight = filteredRows.length * rowHeight + headerHeight + padding;
  dynamicHeight = Math.min(dynamicHeight, maxHeight); // Ensure the height is at most maxHeight

  return (
    <Box sx={{ height: dynamicHeight }}>
      <DataGridBasic
        autoPageSize={true}
        data={{
          rows: rows?.filter(
            (item) => (selected ? item[filterValue] === comparator : true),
            []
          ),
          columns: columns,
        }}
      />
    </Box>
  );
};
export default Accounts;
