import React from "react";
import Button from "@mui/material/Button";
export const DatePresetButton = ({ id, text, date, dateFunction, onClick }) => {
  return (
    <Button
      key={id}
      onClick={(e) => onClick(dateFunction(date), e)}
      aria-label={`Button ${id}`}
    >
      {text}
    </Button>
  );
};
