import React from "react";
import RTKQueryWrapper from "components/DataFetchWrapper/RTKQueryWrapper";
import { ICONS } from "../../../assets/Icons";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import LoadingButton from "../../../components/Buttons/LoadingButton";
import {
  useGetPeDocumentTransactionsQuery,
  useUpdatePeDocumentMutation,
} from "api/apiSlice";
import { ConfirmActionDialog } from "components/Dialogs/ConfirmActionDialog/ConfirmActionDialog";
import { Toolbar } from "@mui/material";
import { useParams, useLocation } from "react-router-dom";
import { PeTransactions } from "./PeTransactions";
import { useTranslation } from "react-i18next";
import PDFDisplay from "components/PDFDisplay/PDFDisplay";
export const Document = () => {
  const { documentId } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const transactionQuery = useGetPeDocumentTransactionsQuery({ documentId });
  const [updateDocument, { isLoading }] = useUpdatePeDocumentMutation();
  const [displayEmailBody, setDisplayEmailBody] = React.useState(true);
  const [displayPDF, setDisplayPDF] = React.useState(true);
  const [currentActionDetails, setCurrentActionDetails] = React.useState(false);

  const handleDocumentNotTransaction = () => {
    handleOpenConfirmDialog({ action: "not_transaction", id: documentId });
  };
  const handleSubmit = (data) => {
    handleOpenConfirmDialog({ action: "submit", id: documentId, data: data });
  };
  const onAccept = () => {
    const { action, id, data, resolve } = currentActionDetails;
    if (action === "delete") {
      updateDocument(id).then(resolve);
    } else if (action === "update") {
      updateDocument({ id, data }).then(resolve);
    }
    // Reset and close dialog after action
    setCurrentActionDetails(null);
  };
  const handleOpenConfirmDialog = ({ action, id, data }) => {
    return new Promise((resolve, reject) => {
      setCurrentActionDetails({ action, id, data, resolve, reject });
    });
  };
  const handleCloseConfirmDialog = () => {
    if (currentActionDetails && currentActionDetails.reject) {
      currentActionDetails.reject(new Error("Action cancelled"));
    }
    setCurrentActionDetails(null);
  };

  return (
    <>
      <Toolbar
        variant="dense"
        sx={{ width: "100%", bgcolor: "secondary.main" }}
      >
        <Tooltip title="Toggle PDF display">
          <IconButton
            sx={{
              opacity: displayPDF ? 1 : 0.5,
              color: "secondary.contrastText",
            }}
            onClick={() => setDisplayPDF((oldState) => !oldState)}
          >
            {ICONS.pdf()}
          </IconButton>
        </Tooltip>
        <Tooltip title="Toggle email body display">
          <IconButton
            sx={{
              color: "secondary.contrastText",
              opacity: displayEmailBody ? 1 : 0.5,
            }}
            onClick={() => setDisplayEmailBody((oldState) => !oldState)}
          >
            {displayEmailBody ? ICONS.visibilityOn() : ICONS.visibilityOff()}
          </IconButton>
        </Tooltip>
        <Tooltip title="Set as `Not Transaction`">
          <IconButton
            sx={{
              color: "error.main",
              marginLeft: "auto",
            }}
            onClick={() => handleDocumentNotTransaction()}
          >
            {ICONS.delete()}
          </IconButton>
        </Tooltip>
      </Toolbar>
      <Stack
        direction={{ md: "row", xs: "column" }}
        width={"100%"}
        height={(theme) => `calc(100% - ${theme.mixins.toolbar.minHeight}px)`}
      >
        {displayPDF && <PDFDisplay src={location.state?.data?.attachment} />}
        {displayEmailBody && (
          <Paper sx={{ flexGrow: 0.6, p: 1 }}>
            <iframe
              srcDoc={location.state?.data?.content}
              height="100%"
              width="100%"
            ></iframe>
          </Paper>
        )}

        <RTKQueryWrapper query={transactionQuery}>
          {(data) => {
            return (
              <Paper
                sx={{
                  display: "flex",
                  width: { xs: "100%", md: "30vw" },
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <PeTransactions data={data} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "right",
                    width: "100%",
                    p: 1,
                  }}
                >
                  <LoadingButton
                    disabled={
                      !data?.primary_transactions.some((t) => !t.processed)
                    }
                    variant="contained"
                    color="success"
                    onClick={handleSubmit}
                    loading={isLoading}
                  >
                    {t("general.submit")}
                  </LoadingButton>
                  <ConfirmActionDialog
                    open={Boolean(currentActionDetails)}
                    text={t(
                      `documentProcessing.actions.submitTransactions.dialog.text`
                    )}
                    title={t(
                      `documentProcessing.actions.submitTransactions.dailog.title`
                    )}
                    onAccept={onAccept}
                    onClose={handleCloseConfirmDialog}
                    onDecline={handleCloseConfirmDialog}
                  />
                </Box>
              </Paper>
            );
          }}
        </RTKQueryWrapper>
      </Stack>
    </>
  );
};

export default Document;
