import React, { Fragment } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import CenterContentWrapper from "containers/CenterContentWrapper/CenterContentWrapper";
import { CircularProgress } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import _ from "lodash";
export const RTKQueryWrapper = ({
  query,
  children,
  LoadingComponent,
  dataIsImmutable,
}) => {
  const { data, error, isLoading, isFetching } = query;
  if (isLoading) {
    if (LoadingComponent) {
      return (
        <CenterContentWrapper>
          <LoadingComponent />
        </CenterContentWrapper>
      );
    }
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    if (error.status === 401) {
      return null;
    } else if (error.status === 503) {
      enqueueSnackbar(
        error?.message ??
          `We are sorry that the underlying portfolio system is failing! Lets hope they are able to fix it [${error?.status}]`,
        {
          variant: "error",
        }
      );
    } else {
      enqueueSnackbar(
        error?.message ?? `Error fetching data[${error?.status}]`,
        {
          variant: "error",
        }
      );
    }
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Typography variant={"body2"} color={"error"}>
          {typeof error === "string" ? error : "An error occured"}
        </Typography>
      </Box>
    );
  }

  const content = children(dataIsImmutable ? data : _.cloneDeep(data));

  return (
    <Fragment>
      {content}

      {isFetching && (
        // Overlay with spinner shown during refetching
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "rgba(255, 255, 255, 0.7)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2, // Ensure it covers the content
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Fragment>
  );
};

export default RTKQueryWrapper;
