import React, { Fragment, useEffect, useReducer, useCallback } from "react";
import { useGridApiContext } from "@mui/x-data-grid-premium";
import Typography from "@mui/material/Typography";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import Popper from "@mui/material/Popper";
import { ICONS } from "../../../assets/Icons";
import { Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
const INITIAL_STATE = {
  open: false,
  isMenuOpened: false,
  menuAnchorEl: null,
  draggingElement: null,
};
export const DataGridGroupByButton = () => {
  const apiContext = useGridApiContext();
  const [state, dispatch] = useReducer(dataGridReducer, INITIAL_STATE);
  const { t } = useTranslation();
  const canBeMenuOpened = state.isMenuOpened && Boolean(state.menuAnchorEl);
  const popperId = canBeMenuOpened ? "transition-popper" : undefined;
  const rowGroupingModel = apiContext.current.exportState().rowGrouping.model;

  const handleRemoveGrouping = useCallback((field) => {
    apiContext.current.removeRowGroupingCriteria(field);
    dispatch({ type: "removeGrouping", value: field });
  }, []);
  const handleAddGrouping = useCallback((colDef) => {
    apiContext.current.addRowGroupingCriteria(colDef.field);
    dispatch({ type: "addGrouping", value: colDef });
  }, []);

  const handlePopperAnchorClick = (event) => {
    dispatch({ type: "togglePopper", element: event.currentTarget });
    event.stopPropagation();
  };

  const handleClosePopper = () => {
    dispatch({ type: "closePopper" });
  };
  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      dispatch({ type: "closePopper" });
    } else if (event.key === "Escape") {
      dispatch({ type: "closePopper" });
    }
  };
  const handleRowGroupingChange = () => {
    console.log("rowGroupingModelChange");
  };

  const handleHeaderDrop = () => {
    if (state.draggingElement) {
      apiContext.current.addRowGroupingCriteria(state.draggingElement.field);
      dispatch({ type: "addGrouping", value: state.draggingElement });
    }
  };
  const handleDragStart = (header) => {
    if (header?.colDef?.groupable) {
      dispatch({ type: "setDraggingElement", element: header });
    }
  };
  const handleDragEnd = () => {
    dispatch({ type: "removeDraggingElement", element: null });
  };

  useEffect(() => {
    const cleanUpDragStart = apiContext.current.subscribeEvent(
      "columnHeaderDragStart",
      handleDragStart
    );
    const cleanUpDragEnd = apiContext.current.subscribeEvent(
      "columnHeaderDragEnd",
      handleDragEnd
    );
    const cleanUpRowGroupingModelChange = apiContext.current.subscribeEvent(
      "rowGroupingModelChange",
      handleRowGroupingChange
    );
    return () => {
      cleanUpDragStart();
      cleanUpDragEnd();
      cleanUpRowGroupingModelChange();
    };
  }, [apiContext]);

  return (
    <Fragment>
      <Button
        startIcon={ICONS.groupBy()}
        size={"small"}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDrop={handleHeaderDrop}
        onClick={handlePopperAnchorClick}
        aria-describedby={popperId}
        type="button"
        id="group-by-button"
        aria-controls={state.isMenuOpened ? "group-by-menu" : undefined}
        aria-expanded={state.isMenuOpened ? "true" : undefined}
        aria-haspopup="true"
      >
        {t("dataGrid.toolbarButtons.groupBy")}
      </Button>
      <ClickAwayListener onClickAway={handleClosePopper}>
        <Popper
          id={popperId}
          open={state.isMenuOpened}
          anchorEl={state.menuAnchorEl}
          role={undefined}
          transition
          placement="bottom-start"
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <MenuList
                  dense={true}
                  autoFocusItem={state.isMenuOpened}
                  id="group-by-menu"
                  aria-labelledby="group-by-button"
                  onKeyDown={handleListKeyDown}
                >
                  {rowGroupingModel.map((field, index, array) => {
                    return (
                      <GroupByItem
                        key={field}
                        item={
                          apiContext.current.getColumnHeaderParams(field).colDef
                        }
                        itemId={field}
                        selected={true}
                        level={index}
                        depth={array.length}
                        onDelete={handleRemoveGrouping}
                        onSelect={handleAddGrouping}
                      />
                    );
                  })}
                  <Divider orientation="horizontal"></Divider>
                  {apiContext.current.getAllColumns().map((colDef) => {
                    if (
                      colDef.groupable &&
                      !rowGroupingModel.includes(colDef.field)
                    ) {
                      return (
                        <GroupByItem
                          key={colDef.field}
                          item={colDef}
                          itemId={colDef.field}
                          selected={false}
                          level={-1}
                          depth={0}
                          onDelete={handleRemoveGrouping}
                          onSelect={handleAddGrouping}
                        />
                      );
                    }
                  })}
                </MenuList>
              </Paper>
            </Fade>
          )}
        </Popper>
      </ClickAwayListener>
    </Fragment>
  );
};

const GroupByItem = (props) => {
  const { item, itemId, selected, onDelete, onSelect, level, ...other } = props;

  return (
    <MenuItem
      sx={{
        display: "flex",
        width: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        pl: level === -1 ? undefined : level + 2,
      }}
      selected={selected}
      onClick={() => onSelect(item)}
      {...other}
    >
      <Typography variant="button">{`${level === -1 ? "" : level + ")"} ${
        item?.headerName ?? "Unknown"
      }`}</Typography>
      {selected ? (
        <IconButton
          edge="end"
          aria-label="delete"
          size="small"
          onClick={(event) => {
            event.stopPropagation();
            onDelete(itemId);
          }}
        >
          {ICONS.delete()}
        </IconButton>
      ) : (
        <IconButton
          edge="end"
          aria-label="delete"
          size="small"
          onClick={(event) => {
            event.stopPropagation();
            onSelect(item);
          }}
        >
          {ICONS.add()}
        </IconButton>
      )}
    </MenuItem>
  );
};
const dataGridReducer = (state, action) => {
  switch (action.type) {
    case "togglePopper":
      return {
        ...state,
        menuAnchorEl: action.element,
        isMenuOpened: !state.isMenuOpened,
      };
    case "closePopper":
      return { ...state, menuAnchorEl: null, isMenuOpened: false };
    case "removeGrouping":
      return { ...state };
    case "addGrouping":
      return { ...state };
    case "setDraggingElement":
      return { ...state, draggingElement: action.element };
    case "removeDraggingElement":
      return { ...state, draggingElement: null };

    default:
      return state;
  }
};

export default DataGridGroupByButton;
