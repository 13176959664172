import { max } from "lodash";

export const parse_aws_url = (url) => {
  if (!url) {
    return null;
  }
  const pathName = new URL(url).pathname;
  return pathName.substring(pathName.lastIndexOf("/") + 1);
};
export const saveTableState = (state, id) => {
  sessionStorage.setItem(id, JSON.stringify(state));
};

export const getTableState = (id) => {
  const stringifiedState = sessionStorage.getItem(id || "");
  try {
    return JSON.parse(stringifiedState);
  } catch {
    return null;
  }
};
export const resetTableState = (id) => sessionStorage.removeItem(id);

export const CUSTOM_AGGREGATION_FUNCTIONS = {
  moneyMultiple: {
    apply: () => {
      return null;
    },
    valueFormatter: (params) => {
      const colDef = params.api.getColumn(params.field);
      // console.log("value formatter", params, colDef, colDef?.moneyMultiple);

      return Intl.NumberFormat(undefined, {
        style: "decimal",
        maximumFractionDigits: colDef?.decimals || 1,
        minimumFractionDigits: colDef?.decimals || 1,
      }).format(colDef?.moneyMultiple);
    },
    label: "mom",
    columnTypes: ["number"],
  },
  irr: {
    apply: () => {
      return null;
    },
    valueFormatter: (params) => {
      const colDef = params.api.getColumn(params.field);

      // console.log("value formatter", params, colDef);
      if (colDef?.irr) {
        return Intl.NumberFormat(undefined, {
          style: "percent",
          maximumFractionDigits: colDef?.decimals || 1,
          minimumFractionDigits: colDef?.decimals || 1,
        }).format(colDef?.irr);
      } else return params.value;
    },
    label: "irr",
    columnTypes: ["number"],
  },
  twr: {
    apply: () => {
      return null;
    },
    valueFormatter: (params) => {
      const colDef = params.api.getColumn(params.field);

      // console.log("value formatter", params, colDef);
      if (colDef?.twr) {
        return Intl.NumberFormat(undefined, {
          style: "percent",
          maximumFractionDigits: colDef?.decimals || 1,
          minimumFractionDigits: colDef?.decimals || 1,
        }).format(colDef?.twr);
      } else return params.value;
    },
    label: "twr",
    columnTypes: ["number"],
  },
};

export const calculateTableHeight = (
  rows = 25,
  maxHeight = 500,
  padding = 56
) => {
  const headerHeight = 56; // Adjust based on your header's height
  const rowHeight = 52; // Adjust based on your row's height
  // Assuming you want some padding based on your theme

  // Calculate dynamic height
  let dynamicHeight = rows.length * rowHeight + headerHeight + padding;
  dynamicHeight = Math.min(dynamicHeight, maxHeight); // Ensure the height is at most maxHeight
  return dynamicHeight;
};
