export const FA_DATA_TAGS = [
  { type: "FAData", id: "POSITIONS" },
  { type: "FAData", id: "DIRECT_INVESTMENTS" },
  { type: "FAData", id: "PRIVATE_EQUITY" },
  { type: "FAData", id: "DOCUMENT_LIST" },
  { type: "FAData", id: "DOCUMENT_ARCHIVE" },
  { type: "FAData", id: "TRANSACTIONS_OVERVIEW" },
  { type: "FAData", id: "PERFORMANCE_TIMESERIES_CHART" },
  { type: "FAData", id: "PERFORMANCE_PERIOD_BAR_CHART" },
  { type: "FAData", id: "PERFORMANCE_TEXT_BOXES" },
  { type: "FAData", id: "PERFORMANCE_DRILLDOWN" },
  { type: "FAData", id: "PERFORMANCE_CONTRIBUTION" },
  { type: "FAData", id: "PERFORMANCE_TABLE" },
  { type: "FAData", id: "ASSET_CLASS_PIE" },
  { type: "FAData", id: "TOTAL_MARKET_VALUE_AND_RETURN" },
  { type: "FAData", id: "NAV_BRIDGE" },
  { type: "FAData", id: "ASSET_ALLOCATION" },
  { type: "FAData", id: "TIME_SERIES_MONTHLY" },
  { type: "FAData", id: "TIME_SERIES_DAILY" },
  { type: "FAData", id: "SECURITIES_RETURNS" },
  { type: "FAData", id: "TOP_BOTTOM_CONTRIBUTORS" },
  { type: "FAData", id: "NET_CASH_FLOWS" },
  { type: "FAData", id: "LIQUIDITY_PAGE" },
  { type: "FAData", id: "ENTITY_CHART" },
  { type: "FAData", id: "ALLOCATION_SECTOR" },
  { type: "FAData", id: "ALLOCATION_ASSET_CLASS" },
  { type: "FAData", id: "TOTAL_MARKET_VALUE_AND_RETURN_CUSTOM" },
  { type: "FAData", id: "AETE_WATERFALL" },
  { type: "FAData", id: "BANK_BALANCE" },
  { type: "FAData", id: "CURRENCY_EXPOSURE" },
  { type: "FAData", id: "EXTERNAL_MANDATES" },
  { type: "FAData", id: "TRANSACTION_OPTIONS" },
  { type: "FAData", id: "PERFORMANCE_TWR_VS_VOLATILITY" },
  { type: "FAData", id: "CURRENCY_EXPOSURE_CHART_UNHEDGED" },
  { type: "FAData", id: "CURRENCY_EXPOSURE_CHART" },
  { type: "FAData", id: "PERFORMANCE_TWR_VS_VOLATILITY" },
  { type: "FAData", id: "INVESTOR_COMPONENTS" },
  { type: "FAData", id: "GET_PORTFOLIOS" },
  { type: "FAData", id: "ALLOCATION_BOXES" },
  { type: "FAData", id: "SECURITY_TYPE_CHART" },
  { type: "FAData", id: "PERFORMANCE_TIME_SERIES" },
  { type: "FAData", id: "RETURN_CONTRIBUTION_TABLE" },
  { type: "FAData", id: "ANALYTICS_OPTIONS" },
  { type: "FAData", id: "PERFORMANCE_RETURN_BOXES" },
  { type: "FAData", id: "ALLOCATIONS_DYNAMIC_PIE_CHART" },
];

export const APPLICATION_STATE_TAGS = [
  { type: "AppState", id: "DOCUMENT_PROCESSING_DOC_LIST" },
];
export const ON_CALCULATE_TAGS = [
  { type: "OnCalculate", id: "PERFORMANCE_TABLE" },
];
export const UTILITY_TAGS = [{ type: "Options", id: "ANALYTICS_OPTIONS" }];
