import React, { memo } from "react";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
  useGridApiContext,
} from "@mui/x-data-grid-premium";
import Button from "@mui/material/Button";

import Box from "@mui/material/Box";

import DataGridGroupByButton from "./DataGridGroupByButton";
import { ICONS } from "../../../assets/Icons";

import { GridPresetMenu } from "./GridPresetMenu/GridPresetMenu";
import { useTranslation } from "react-i18next";

export const CustomToolbar = memo(
  ({ searchable, printOptions, gridId, ...props }) => {
    const apiRef = useGridApiContext();
    const handleResetState = () => {
      apiRef.current.restoreState(props?.initial_state);
    };
    const { t } = useTranslation();

    return (
      <GridToolbarContainer sx={{ justifyContent: "space-between" }}>
        <Box>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <DataGridGroupByButton />
          <GridToolbarExport printOptions={printOptions} />
        </Box>
        {searchable && (
          <React.Fragment>
            <GridToolbarQuickFilter {...props} />
          </React.Fragment>
        )}
        <Box>
          <GridPresetMenu gridId={gridId} apiRef={apiRef}></GridPresetMenu>
          <Button
            startIcon={ICONS.reset()}
            size={"small"}
            onClick={handleResetState}
            type="button"
            id="reset-state-button"
          >
            {t("general.reset")}
          </Button>
        </Box>
      </GridToolbarContainer>
    );
  }
);

export default CustomToolbar;
