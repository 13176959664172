const numberFormatOptions = {
  style: "currency",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};
export const TRANSACTION_FORM_DEFAULT_VALUES = {
  portfolio: null,
  instrument_type: null,
  security_name: null,
  currency: "",
  transaction_type: null,
  transaction_date: null,
  settlement_date: null,
  connected_documents: [],
  amount: "",
  cost: "",
  price: "",
  bank_account: null,
  drawdown_distribution_number: "",
  not_transaction: false,
  add_email: false,
  description: "",
};

export const calcTotalTradeAmount = (
  cost,
  price,
  amount,
  currency,
  transactionType
) => {
  if (!cost) {
    cost = 0;
  }
  if (!amount) {
    amount = 0;
  }
  const tmp1 = Number(amount) * Number(price ? price : 1);
  const totalTradeAmount =
    tmp1 -
    Number(["BUY", "Buy", "buy", "B"].includes(transactionType) ? -cost : cost);

  try {
    const formattedAmount = new Intl.NumberFormat(undefined, {
      ...numberFormatOptions,
      currency,
    }).format(totalTradeAmount);
    return formattedAmount;
  } catch (error) {
    return totalTradeAmount.toFixed(2);
  }
};
