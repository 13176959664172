/**
 * Generates dynamic routes based on the allowed routes.
 * @param {Object} dynamicRoutes - Object containing routes to choose from.
 * @param {Object} allowedRoutes - Object containing allowed routes as keys.
 * @returns {Array} - Array of dynamically generated route configurations.
 */
export const generateDynamicRoutes = (dynamicRoutes, allowedRoutes) => {
  // Filter dynamicRoutes based on whether their path exists in allowedRoutes
  const generatedRoutes = dynamicRoutes.filter((route) =>
    allowedRoutes.some((allowedRoute) => allowedRoute.value === route.path)
  );

  // If there are any generatedRoutes, clone the first one as the index route
  if (generatedRoutes.length > 0) {
    const indexRoute = { ...generatedRoutes[0], index: true, path: undefined };
    // Prepend the indexRoute to the generatedRoutes array
    generatedRoutes.unshift(indexRoute);
  }
  return generatedRoutes;
};
