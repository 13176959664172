import React from "react";
import Card from "@mui/material/Card";

import { CardContent, Typography } from "@mui/material";
import NumberFormatter from "components/NumberFormatter/NumberFormatter";
import { Box } from "@mui/system";
const DataCard = ({
  align,
  color,
  decimals,
  format,
  headerName,
  height,
  value,
  metaData,
}) => {
  return (
    <Card
      sx={{
        px: 2,
        py: 1,
        height: height ?? "100%",
        textAlign: align ?? "center",
        bgcolor: color ?? "info.main",
        color: (theme) =>
          theme.palette.getContrastText(color) ?? "info.contrastText",
      }}
    >
      <CardContent
        sx={{
          p: 0,
          pb: 0,
          display: "flex",
          position: "relative",
          flexDirection: "column",
          "&:last-child": { pb: 0 },
        }}
      >
        <Typography variant="h6">{headerName}</Typography>

        <Typography variant="h4" textAlign={"center"}>
          <NumberFormatter
            value={value}
            options={{
              minimumFractionDigits: decimals ?? 0,
              maximumFractionDigits: decimals ?? 0,
              style: format,
            }}
          ></NumberFormatter>
        </Typography>
        {metaData && (
          <Box sx={{ position: "absolute", right: 0, bottom: 0 }}>
            <Typography variant="caption">{metaData}</Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default DataCard;
