import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";
const initialState = {
  AnalyticsAllocError: null,
  AnalyticsAllocData: null,
  AnalyticsAllocLoading: false,
  PEListError: null,
  PEListData: null,
  PEListLoading: false,
  PEChartError: null,
  PEChartData: null,
  PEChartLoading: false,
  TotalMVError: null,
  TotalMVData: null,
  TotalMVLoading: false,
  MandatesData: null,
  MandatesLoading: false,
  MandatesError: null,
  AltorData: null,
  AltorLoading: false,
  AltorError: null,
  DirectInvestmentsData: null,
  DirectInvestmentsLoading: false,
  DirectInvestmentsError: null,
  XrayData: null,
  XrayLoading: false,
  XrayError: null,
  CurrencyData: null,
  CurrencyLoading: false,
  CurrencyError: null,
  documentListData: null,
  documentListLoading: false,
  documentListError: null,
  number_formatsData: null,
  number_formatsLoading: false,
  number_formatsError: null,
};

const setNumberFormats = (state, action) => {
  return updateObject(state, {
    number_formatsData: action.value,
  });
};
//DATA FETCH ANALYTICS

//          DATA GENERAL REDUCER
//-------------------------------------
const dataFStart = (state, action) => {
  return updateObject(state, {
    [`${action.id}Error`]: null,
    [`${action.id}Loading`]: true,
    dataLoading: true,
  });
};
const dataFSuccess = (state, action) => {
  return updateObject(state, {
    [`${action.id}Data`]: action.data,
    [`${action.id}Error`]: null,
    [`${action.id}Loading`]: false,
    dataLoading: false,
  });
};
const dataFFail = (state, action) => {
  return updateObject(state, {
    [`${action.id}Data`]: null,
    [`${action.id}Error`]: action.error,
    dataLoading: false,
  });
};

const dataFlush = (state, action) => {
  return initialState;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DATA_F_START:
      return dataFStart(state, action);
    case actionTypes.DATA_F_SUCCESS:
      return dataFSuccess(state, action);
    case actionTypes.DATA_F_FAIL:
      return dataFFail(state, action);
    case actionTypes.DATA_FLUSH:
      return dataFlush(state, action);
    case actionTypes.SET_NUMBER_FORMATS:
      return setNumberFormats(state, action);
    default:
      return state;
  }
};

export default reducer;
