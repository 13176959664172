import React from "react";
import PropTypes from "prop-types";
import { MenuItem, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
export const FormInputDropdown = ({
  options,
  filter,
  callBack,
  classes,
  name,
  control,
  label,
  size,
  disabled,
  isRequired,
}) => {
  const { t } = useTranslation();
  if (!options) {
    return null;
  }
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: "This field is required" }}
      render={({ field, fieldState: { error } }) => {
        const { onChange } = field;
        const handleChange = (e) => {
          onChange(e);
          if (callBack) {
            callBack(e);
          }
        };
        return (
          <TextField
            select
            size={size || "medium"}
            fullWidth
            disabled={disabled}
            className={classes?.selectInput}
            required={!!isRequired}
            label={label}
            variant="outlined"
            error={!!error}
            helperText={error?.message}
            {...field}
            onChange={handleChange}
          >
            {options
              .filter((i) =>
                Array.isArray(i.comparator)
                  ? i.comparator.includes(filter)
                  : typeof filter === "string"
                  ? i.comparator === filter
                  : true
              )
              ?.map((option, index) => (
                <MenuItem key={index} value={option?.value}>
                  {t(`inputs.options.${option.label}`)}
                </MenuItem>
              ))}
          </TextField>
        );
      }}
    />
  );
};
FormInputDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
      value: PropTypes.any,
    })
  ),
  classes: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  size: PropTypes.string,
  isRequired: PropTypes.bool,
  maxLength: PropTypes.number,
};
export default FormInputDropdown;
