import React from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  Fade,
  Card,
  CardContent,
  Paper,
  Box,
  CardActions,
} from "@mui/material";
import RTKLazyQueryWrapper from "components/DataFetchWrapper/RTKLazyQueryWrapper";
import { PlotlyChart, PlotlyChartDrilldown } from "components/PlotlyChart";
import {
  useLazyGetPerformanceDrilldownQuery,
  useLazyGetPerfTwrContrVsVolChartQuery,
} from "api/apiSlice";
import ReturnContributionTable from "./RetunContributionTable";
import YSSwitchPlain from "components/YSSwitchPlain";
import { t } from "i18next";

const TimeSeries = () => {
  const startDate = useSelector((state) => state.user.customStartDate);
  const clientSettings = useSelector(
    (state) => state.user.chosen_client?.settings
  );
  const { client_info_fa } = useSelector((state) => state.user.chosen_client);
  const endDate = useSelector((state) => state.user.customEndDate);
  const performanceDrilldownQuery = useLazyGetPerformanceDrilldownQuery();
  const [localOrBase, setLocalOrBase] = React.useState(false);
  const [returnOrtwr, setReturnOrTwr] = React.useState(false);
  const handleBaseOrLocalSwitch = () => {
    setLocalOrBase((oldState) => !oldState);
  };
  const handleReturnOrTwrSwitch = () => {
    setReturnOrTwr((oldState) => !oldState);
  };
  const peformanceTwrVsVolatilityQuery =
    useLazyGetPerfTwrContrVsVolChartQuery();
  return (
    <Grid container spacing={2}>
      <Fade in={true} timeout={2000}>
        <Grid item xs={12} md={12} xl={6} sx={{ mt: 2 }}>
          <Card
            sx={{
              marginTop: "80px",
              marginLeft: "40px",
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.primary.light
                  : theme.palette.primary.dark,
              overflow: "visible",
              minHeight: "500px",
            }}
          >
            <CardContent>
              <Paper
                sx={{
                  height: "100%",
                  p: 1,
                  textAlign: "center",
                  margin: "-80px 0px 0px -40px",
                  borderRadius: "0px 0px 0px 0px",
                }}
              >
                <RTKLazyQueryWrapper
                  query={performanceDrilldownQuery}
                  queryParams={{ startDate, endDate }}
                  fetchOnMount
                >
                  {(data) => {
                    return (
                      <PlotlyChartDrilldown
                        isPercentageData={returnOrtwr}
                        data={
                          data?.[localOrBase ? "local" : "base"]?.[
                            returnOrtwr ? "twr" : "return"
                          ]
                        }
                      />
                    );
                  }}
                </RTKLazyQueryWrapper>
              </Paper>
            </CardContent>
            <CardActions>
              <Box
                sx={{
                  height: "50px",
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  color: "primary.contrastText",
                }}
              >
                <YSSwitchPlain
                  isChecked={localOrBase}
                  rightValue={t("financeTerms.local")}
                  onClick={handleBaseOrLocalSwitch}
                  leftValue={t("financeTerms.base", {
                    currency: client_info_fa?.currency,
                  })}
                ></YSSwitchPlain>
                <YSSwitchPlain
                  isChecked={returnOrtwr}
                  rightValue={t("financeTerms.twr")}
                  onClick={handleReturnOrTwrSwitch}
                  leftValue={t("financeTerms.return")}
                ></YSSwitchPlain>
              </Box>
            </CardActions>
          </Card>
        </Grid>
      </Fade>
      {clientSettings?.show_performance_table && <ReturnContributionTable />}
      {clientSettings?.show_performance_contribution_bubble_chart && (
        <Fade in={true} timeout={2000}>
          <Grid item xs={12} md={6} xl={6} sx={{ mt: 2 }}>
            <Card
              sx={{
                marginTop: "80px",
                marginRight: "40px",
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.primary.light
                    : theme.palette.primary.dark,
                overflow: "visible",
                minHeight: "500px",
              }}
            >
              <CardContent>
                <Paper
                  sx={{
                    height: "100%",
                    p: 1,
                    textAlign: "center",
                    margin: "-80px -40px 0px 0px",
                    borderRadius: "0px 0px 0px 0px",
                  }}
                >
                  <RTKLazyQueryWrapper
                    query={peformanceTwrVsVolatilityQuery}
                    queryParams={{ startDate, endDate }}
                    fetchOnMount
                  >
                    {(data) => (
                      <PlotlyChart
                        {...data?.chart}
                        isPercentageData={true}
                        minHeightChart={"536px"}
                      />
                    )}
                  </RTKLazyQueryWrapper>
                </Paper>
              </CardContent>
              <CardActions>
                <Box sx={{ height: "50px" }}></Box>
              </CardActions>
            </Card>
          </Grid>
        </Fade>
      )}
    </Grid>
  );
};

export default TimeSeries;
