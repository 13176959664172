import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import CurrencyBankCard from "./CurrencyBankCard";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useGetBankBalancesQuery } from "api/apiSlice";
import RTKQueryWrapper from "components/DataFetchWrapper/RTKQueryWrapper";
import Accounts from "./Accounts";
import Loans from "./Loans";
import YSSwitchPlain from "components/YSSwitchPlain";
const Companies = () => {
  const date = useSelector((state) => state.user.endDate);
  const clientCurrency = useSelector(
    (state) => state.user.chosen_client?.client_info_fa?.currency
  );
  const { t } = useTranslation();
  const [sortBy, setSorBy] = useState(null);
  const [selected, setSelected] = React.useState(null);
  const bankQuuery = useGetBankBalancesQuery({
    endDate: date,
  });
  const handleCardClick = (data) => {
    setSelected(data);
  };
  return (
    <RTKQueryWrapper query={bankQuuery}>
      {(data) => (
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                ml: 1,
              }}
            >
              <Typography> {t("general.sortBy")}</Typography>
              <YSSwitchPlain
                leftValue={"A-Z"}
                isChecked={!!sortBy}
                rightValue={t("bank.mv")}
                onClick={() => setSorBy((oldValue) => !oldValue)}
              />
            </Box>
          </Grid>
          <Grid item xs={12} p={1}>
            <Grid container spacing={2}>
              {Array.isArray(data?.currencies) &&
                data?.currencies
                  ?.sort((a, b) => {
                    return sortBy
                      ? b.value - a.value
                      : a.currencyCode.localeCompare(b.currencyCode);
                  })
                  .map((currency) => (
                    <Grid
                      item
                      xs={12}
                      md={4}
                      lg={3}
                      p={2}
                      key={currency?.currencyCode}
                      display={"flex"}
                    >
                      <CurrencyBankCard
                        data={currency}
                        clientCurrency={clientCurrency}
                        onClick={handleCardClick}
                        selected={!!selected}
                      />
                    </Grid>
                  ))}
            </Grid>
          </Grid>
          {selected && (
            <Grid
              item
              xs={12}
              md={4}
              lg={3}
              p={2}
              key={selected.currency}
              id="selected-currency"
            >
              <CurrencyBankCard
                data={{ ...selected }}
                clientCurrency={clientCurrency}
                onClick={handleCardClick}
                selected={selected}
              />
            </Grid>
          )}
          <Grid item xs={12} maxHeight={"600px"} p={2}>
            <Typography> {t("bank.accounts")} </Typography>
            <Accounts selected={selected} accounts={data?.accounts} />
          </Grid>
          <Grid item xs={12} p={2}>
            <Typography> {t("bank.loans")}</Typography>
            <Loans selected={selected} loans={data?.loans} />
          </Grid>
        </Grid>
      )}
    </RTKQueryWrapper>
  );
};

export default Companies;
