export const retrieveData = (data, key) => {
  if (!data) return null;
  if (!key)
    return { chart_data: data.chart_data, chart_config: data.chart_config };

  const element = data.drilldown_lvl_1.find(
    (item) => Object.keys(item)[0] === key
  );
  return element
    ? {
        chart_data: element[key].chart_data,
        chart_config: element[key].chart_config,
      }
    : {};
};
