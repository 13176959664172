import * as actionTypes from "./actionTypes";

export const setVisibility = () => {
  return {
    type: actionTypes.SET_VISIBILITY_SIDEBAR,
  };
};
export const setChartData = () => {
  return {
    type: actionTypes.SET_CHART_DATA,
  };
};
export const setTabsValue = (value, id = "") => {
  return {
    type: actionTypes.SET_TABS_VALUE,
    value: value,
    id: id,
  };
};
export const setTheme = (theme) => {
  return {
    type: actionTypes.SET_THEME,
    value: theme,
  };
};

export const setStartDate = (date, id = "") => {
  return {
    type: actionTypes.SET_START_DATE,
    value: date,
    id: id,
  };
};
export const setEndDate = (date, id = "") => {
  return {
    type: actionTypes.SET_END_DATE,
    value: date,
    id: id,
  };
};
export const setMaxDate = (date, id = "") => {
  return {
    type: actionTypes.SET_MAX_DATE,
    value: date,
    id: id,
  };
};
export const setMinDate = (date, id = "") => {
  return {
    type: actionTypes.SET_MIN_DATE,
    value: date,
    id: id,
  };
};
export const setPresetDate = (date, id = "") => {
  return {
    type: actionTypes.SET_PRESET_DATE,
    value: date,
    id: id,
  };
};
export const setDateError = (error, id = "") => {
  return {
    type: actionTypes.SET_DATE_ERROR,
    value: error,
    id: id,
  };
};
export const setGridState = (state, id = "") => {
  return {
    type: actionTypes.SET_GRID_STATE,
    value: state,
    id: id,
  };
};

export const refetch = () => {
  return {
    type: actionTypes.REFETCH,
  };
};

export const set_transactions_modal_state = (state) => {
  return {
    type: actionTypes.SET_TRANSACTIONS_MODAL_STATE,
    value: state,
  };
};
