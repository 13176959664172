import { KEEP_UNUSED_DATA as keepUnusedDataFor } from "constants";

export const dataEndpoints = (builder) => {
  return {
    getPositionsData: builder.query({
      query: ({ endDate }) => ({
        url: `fadata/standardComponents/positions/${
          endDate ? `?endDate=${endDate}` : ""
        }`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,

      providesTags: [{ type: "FAData", id: "POSITIONS" }],
    }),
    getDirectInvestmentsData: builder.query({
      query: ({ endDate }) => ({
        url: `fadata/standardComponents/directInvestments/${
          endDate ? `?endDate=${endDate}` : ""
        }`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "DIRECT_INVESTMENTS" }],
    }),

    getDocumentList: builder.query({
      query: ({ endDate }) => ({
        url: `documents/list_documents/${endDate ? `?endDate=${endDate}` : ""}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "DOCUMENT_LIST" }],
    }),
    updateDocument: builder.mutation({
      query: (body) => ({
        url: `documents/document/`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: body,
      }),
      keepUnusedDataFor,
    }),
    getTransactionsOptions: builder.query({
      query: () => ({
        url: `base_data/transaction_options/`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "TRANSACTION_OPTIONS" }],
    }),
    getDocumentArchive: builder.query({
      query: ({ endDate, startDate }) => ({
        url: `fadata/document_archive/?endDate=${endDate}&startDate=${startDate}&componentName=investorDocuments`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "DOCUMENT_ARCHIVE" }],
    }),
    getExcelDownloadsOptions: builder.query({
      query: ({ endDate }) => ({
        url: `fadata/excel_files/?endDate=${endDate}&componentName=getOptions`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "STRUCTURE_DATA", id: "EXCEL_OPTIONS" }],
    }),
    getTransactionsOverview: builder.query({
      query: ({ endDate, startDate }) => ({
        url: `fadata/standardComponents/transactionTable/?endDate=${endDate}&startDate=${startDate}&componentName=transactions`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "TRANSACTIONS_OVERVIEW" }],
    }),

    getAssetClassPie: builder.query({
      query: ({ endDate }) => ({
        url: `fadata/standardComponents/assetClassPie/?endDate=${endDate}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "ASSET_CLASS_PIE" }],
    }),
    getTotalMarketValueAndReturn: builder.query({
      query: ({ endDate }) => ({
        url: `fadata/standardComponents/totalMarketValueAndReturn/?endDate=${endDate}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "TOTAL_MARKET_VALUE_AND_RETURN" }],
    }),
    getNavBridge: builder.query({
      query: ({ endDate }) => ({
        url: `fadata/standardComponents/navBridge/?endDate=${endDate}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "NAV_BRIDGE" }],
    }),
    getBankBalances: builder.query({
      query: ({ endDate }) => ({
        url: `fadata/standardComponents/bankBalances/?endDate=${endDate}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "BANK_BALANCE" }],
    }),
    getAssetAllocation: builder.query({
      query: ({ endDate, startDate }) => ({
        url: `fadata/custom_components/?componentName=assetAllocation&endDate=${endDate}&startDate=${startDate}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "ASSET_ALLOCATION" }],
    }),
    getWaterfall: builder.query({
      query: ({ endDate, startDate }) => ({
        url: `fadata/custom_components/?componentName=aeternumWaterfall&endDate=${endDate}&startDate=${startDate}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "AETE_WATERFALL" }],
    }),
    getTotalMarketValueAndReturnCustom: builder.query({
      query: ({ endDate, startDate }) => ({
        url: `fadata/custom_components/?componentName=totalMarketValueAndReturn&endDate=${endDate}&startDate=${startDate}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [
        { type: "FAData", id: "TOTAL_MARKET_VALUE_AND_RETURN_CUSTOM" },
      ],
    }),
    getTimeSeriesMonthly: builder.query({
      query: ({ endDate, startDate }) => ({
        url: `fadata/custom_components/?componentName=timeSeriesMonthly&endDate=${endDate}&startDate=${startDate}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "TIME_SERIES_MONTHLY" }],
    }),
    getTimeSeriesDaily: builder.query({
      query: ({ endDate, startDate }) => ({
        url: `fadata/custom_components/?componentName=timeSeriesDaily&endDate=${endDate}&startDate=${startDate}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "TIME_SERIES_DAILY" }],
    }),
    getAllocationAssetClass: builder.query({
      query: ({ endDate, startDate }) => ({
        url: `fadata/custom_components/?componentName=aeternumAllocationAssetClass&endDate=${endDate}&startDate=${startDate}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "ALLOCATION_ASSET_CLASS" }],
    }),
    getAllocationSector: builder.query({
      query: ({ endDate, startDate }) => ({
        url: `fadata/custom_components/?componentName=aeternumAllocationSector&endDate=${endDate}&startDate=${startDate}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "ALLOCATION_SECTOR" }],
    }),
    getSecurityReturns: builder.query({
      query: ({ endDate, startDate, currency }) => ({
        url: `fadata/custom_components/?componentName=securityReturns&endDate=${endDate}&startDate=${startDate}&currency=${currency}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "SECURITIES_RETURNS" }],
    }),
    getTopBottomContributors: builder.query({
      query: ({ endDate, startDate }) => ({
        url: `fadata/custom_components/?componentName=topBottom&endDate=${endDate}&startDate=${startDate}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "TOP_BOTTOM_CONTRIBUTORS" }],
    }),
    getNetCashFlow: builder.query({
      query: ({ endDate, startDate }) => ({
        url: `fadata/custom_components/?componentName=netCashFlow&endDate=${endDate}&startDate=${startDate}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "NET_CASH_FLOWS" }],
    }),
    getNewAttribution: builder.query({
      query: ({ endDate, portfolioId }) => ({
        url: `fadata/NewAttribution/${endDate}/super_get/`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: { date: endDate, portfolio: portfolioId },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "NEW_ATTRIBUTION" }],
    }),
    getEntityChart: builder.query({
      query: ({ endDate }) => ({
        url: `fadata/standardComponents/entityChart/?endDate=${endDate}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "ENTITY_CHART" }],
    }),
    getLiquidityPage: builder.query({
      query: ({ endDate }) => ({
        url: `fadata/standardComponents/liquidityPage/?endDate=${endDate}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "LIQUIDITY_PAGE" }],
    }),
    getExternalMandates: builder.query({
      query: ({ endDate }) => ({
        url: `/fadata/Mandates/${endDate}/super_get/`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: { date: endDate },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "EXTERNAL_MANDATES" }],
    }),

    // Performance time weighted return vs volatility
    getPerfTwrContrVsVolChart: builder.query({
      query: ({ startDate, endDate }) => ({
        url: `/fadata/standardComponents/perfTwrContrVsVolChart/?endDate=${endDate}&startDate=${startDate}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "PERFORMANCE_TWR_VS_VOLATILITY" }],
    }),

    getCurrenciesExposureDetail: builder.query({
      query: ({ endDate }) => ({
        url: `fadata/standardComponents/currencyExposureDetail/?endDate=${endDate}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "CURRENCY_EXPOSURE" }],
    }),
    getCurrencyExposureChart: builder.query({
      query: ({ endDate }) => ({
        url: `fadata/standardComponents/currencyExposureChart/?endDate=${endDate}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "CURRENCY_EXPOSURE_CHART" }],
    }),
    getCurrencyExposureChartUnhedged: builder.query({
      query: ({ endDate }) => ({
        url: `fadata/standardComponents/currencyExposureChartUnhedged/?endDate=${endDate}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [
        { type: "FAData", id: "CURRENCY_EXPOSURE_CHART_UNHEDGED" },
      ],
    }),
    getAllocationBoxesData: builder.query({
      query: ({ endDate, startDate }) => ({
        url: `fadata/standardComponents/allocationBoxes/?endDate=${endDate}&startDate=${startDate}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "ALLOCATION_BOXES" }],
    }),

    getSecurityTypeChart: builder.query({
      query: ({ endDate, startDate }) => ({
        url: `fadata/standardComponents/securityTypeChart/?endDate=${endDate}&startDate=${startDate}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "SECURITY_TYPE_CHART" }],
    }),
    getReturnContributionTable: builder.query({
      query: ({ endDate, startDate }) => ({
        url: `fadata/standardComponents/returnContributionTable/?endDate=${endDate}&startDate=${startDate}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "RETURN_CONTRIBUTION_TABLE" }],
    }),
    getContributionSectorTable: builder.query({
      query: ({ endDate, startDate, group, group_code }) => ({
        url: `fadata/standardComponents/contributionSectorTable/?endDate=${endDate}&startDate=${startDate}&group=${group}&group_code=${group_code}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "CONTRIBUTION_SECTOR_TABLE" }],
    }),
    getAnalyticsOptions: builder.query({
      query: ({ endDate, startDate }) => ({
        url: `fadata/standardComponents/getAnalyticsOptions/?endDate=${endDate}&startDate=${startDate}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "Options", id: "ANALYTICS_OPTIONS" }],
    }),
    /**
     * Investor specific endpoints
     */
    getInvestorPortfolios: builder.query({
      query: ({ endDate }) => ({
        url: `/fadata/investor_components/?endDate=${endDate}&componentName=getPortfolios`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "GET_PORTFOLIOS" }],
    }),
    getInvestorComponent: builder.query({
      query: ({ endDate, portfolioId, componentName }) => ({
        url: `/fadata/investor_components/?endDate=${endDate}&portfolioId=${portfolioId}&componentName=${componentName}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "INVESTOR_COMPONENTS" }],
    }),
    /**
     * @todo replace super_get endpoints with new endpoints
     *  START
     */
    getPrivateEquityData: builder.query({
      query: ({ endDate, id }) => ({
        url: `fadata/${id}/${endDate}/super_get/${
          endDate ? `?endDate=${endDate}` : ""
        }`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: { date: endDate },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "PRIVATE_EQUITY" }],
    }),
    getCustomClientData: builder.query({
      query: ({ endDate }) => ({
        url: `fadata/Altor/${endDate}/super_get/`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: { date: endDate },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "CUSTOM_CLIENT_DATA" }],
    }),
    getXrayData: builder.query({
      query: ({ endDate }) => ({
        url: `fadata/Xray/${endDate}/super_get/`,
        method: "POST",

        body: { date: endDate },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "XRAY_DATA" }],
    }),
    /**
     * FA Doctor endpoints
     **/
    getDataQualityChecksPerClient: builder.query({
      query: ({ endDate }) => ({
        url: `fa_doctor/data_quality_checks_per_client/?endDate=${endDate}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "DATA_QUALITY_CHECKS" }],
    }),
    getHtmlContentTWR: builder.query({
      query: ({ endDate }) => ({
        url: `fa_doctor/twr_check_html/?endDate=${endDate}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
      keepUnusedDataFor,
      providesTags: [{ type: "FAData", id: "TWR_CHECK" }],
    }),
    /**
     * END
     **/
  };
};
