import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Card, CardActions, CardContent } from "@mui/material";
import { styled } from "@mui/material/styles";

const YSChartCard = styled(Card)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(1),
}));
export function ChartCard(props) {
  return (
    <YSChartCard variant="outlined" {...props}>
      <CardContent {...props}>{props.children}</CardContent>
    </YSChartCard>
  );
}
