import React from "react";
import { DataGridTable } from "components/Tables/DataGridTable";
import { useLazyGetPerformanceTableQuery } from "api/apiSlice";
import RTKLazyQueryWrapper from "components/DataFetchWrapper/RTKLazyQueryWrapper";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import _ from "lodash";
export const PerformanceTable = ({
  grouping,
  groupCode,
  startDate,
  endDate,
}) => {
  const client = useSelector((state) => state.user.chosen_client);
  const performanceTableLazyQuery = useLazyGetPerformanceTableQuery();
  return (
    <Box sx={{ height: "75vh" }}>
      <RTKLazyQueryWrapper
        query={performanceTableLazyQuery}
        fetchOnMount={true}
        queryParams={{
          startDate: startDate,
          endDate: endDate,
          grouping: grouping,
          group_code: groupCode,
          update: client?.settings?.updatePerformanceTable,
        }}
      >
        {(data) => {
          return <DataGridTable {...data} id={"performanceTableElite"} />;
        }}
      </RTKLazyQueryWrapper>
    </Box>
  );
};
