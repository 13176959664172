import React, { useState } from "react";

import { calcTotalTradeAmount } from "./utils";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import { Divider, Typography, IconButton } from "@mui/material";
import { ICONS } from "../../../assets/Icons";
import CreateTransactionForm from "./CreateTransactionForm";
import {
  useUpdatePeTransactionMutation,
  useDeletePeTransactionMutation,
} from "api/apiSlice";
export const EditableTransactionCard = ({
  transactionData,
  formSupplementData,
  isOpen,
  onDelete,
  onSave,
  isEditing,
}) => {
  const [updatePeTransaction] = useUpdatePeTransactionMutation();
  const [deletePeTransaction] = useDeletePeTransactionMutation();
  const [state, setState] = useState({
    open: isOpen || false,
    isEditing: isEditing || false,
  });

  const handleClose = () => {
    setState((oldState) => {
      return { ...oldState, isEditing: false };
    });
  };

  const handleTransactionUpdate = async (formData) => {
    await updatePeTransaction({
      transactionId: formData.transaction_id,
      data: formData,
    });
    handleClose();
  };
  const handleDeleteTransaction = () => {
    deletePeTransaction({ transactionId: transactionData?.transaction_id })
      .unwrap()
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Box
      key={transactionData.transaction_id}
      sx={{
        display: "flex",
        flexDirection: "column",
        bgcolor:
          transactionData?.status === "processed"
            ? "secondary.light"
            : "secondary.main",
        color:
          transactionData?.status === "processed"
            ? (theme) =>
                theme.palette.getContrastText(theme.palette.secondary.light)
            : "secondary.contrastText",
        p: 1,
        m: 0.5,
        borderRadius: 1,
      }}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        flexDirection="row"
      >
        <Stack
          direction={"row"}
          spacing={1}
          width={"90%"}
          divider={<Divider orientation="vertical" flexItem></Divider>}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",

              textAlign: "center",
            }}
          >
            <Typography variant="body2"> {"Portfolio"}</Typography>
            {transactionData?.portfolio}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Typography variant="body2"> {"Instrument type"}</Typography>
            {transactionData?.instrumentType}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Typography variant="body2"> {"Total trade amount"}</Typography>
            {calcTotalTradeAmount(
              transactionData?.cost,
              transactionData?.price,
              transactionData?.amount,
              transactionData?.currency,
              transactionData?.transactionType
            )}
          </Box>
        </Stack>
        <IconButton
          sx={{
            color: transactionData.status
              ? (theme) =>
                  theme.palette.getContrastText(theme.palette.secondary.light)
              : "secondary.contrastText",
          }}
          onClick={() =>
            setState((oldState) => {
              return { ...oldState, open: !oldState.open };
            })
          }
        >
          {state.open ? ICONS.arrowUp() : ICONS.arrowDown()}
        </IconButton>
        {transactionData.status !== "processed" && (
          <IconButton
            sx={{
              color: transactionData.status
                ? (theme) =>
                    theme.palette.getContrastText(theme.palette.secondary.light)
                : "secondary.contrastText",
            }}
            onClick={() => {
              setState((oldState) => {
                return {
                  ...oldState,
                  isEditing: !oldState.isEditing,
                  open: true,
                };
              });
            }}
          >
            {state.isEditing ? ICONS.editOff() : ICONS.edit()}
          </IconButton>
        )}
        {transactionData.status !== "processed" && (
          <IconButton
            sx={{
              color:
                transactionData.status === "processed"
                  ? (theme) =>
                      theme.palette.getContrastText(
                        theme.palette.secondary.light
                      )
                  : "secondary.contrastText",
            }}
            onClick={handleDeleteTransaction}
          >
            {ICONS.delete()}
          </IconButton>
        )}
      </Box>

      {state.open && (
        <Stack direction={"column"} divider={<Divider></Divider>}>
          {state.isEditing ? (
            <CreateTransactionForm
              formSupplementData={formSupplementData}
              defaultValues={transactionData}
              formId={transactionData?.transaction_id}
              onSubmit={handleTransactionUpdate}
            ></CreateTransactionForm>
          ) : transactionData ? (
            Object.entries(transactionData ?? {}).map(([key, value]) => {
              if (Array.isArray(value)) {
                return (
                  <Box
                    key={key}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography> {key}</Typography>
                    <Typography variant="body2">
                      {` ${value?.length} `}{" "}
                    </Typography>
                  </Box>
                );
              } else if (typeof value === "object") {
                return (
                  <Box
                    key={key}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography> {key}</Typography>
                    <Typography variant="body2">{value?.label}</Typography>
                  </Box>
                );
              } else if (typeof value === "boolean") {
                return (
                  <Box
                    key={key}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography> {key}</Typography>
                    <Typography variant="body2">
                      {value &&
                        ICONS.checked({ sx: { color: "success.main" } })}
                    </Typography>
                  </Box>
                );
              }
              return (
                <Box
                  key={key}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography> {key}</Typography>
                  <Typography variant="body2">{value}</Typography>
                </Box>
              );
            })
          ) : (
            <Typography> {"No Data"}</Typography>
          )}
        </Stack>
      )}
    </Box>
  );
};
export default EditableTransactionCard;
