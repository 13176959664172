export const userEndpoints = (builder) => {
  return {
    getUserProfile: builder.query({
      query: () => ({
        url: "fadata/userprofile/",
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }),
    }),
    updateUserProfile: builder.mutation({
      query: () => ({
        url: "fadata/userprofile/",
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      }),
    }),
    updateUserSettings: builder.mutation({
      query: ({ data }) => ({
        url: "fadata/user_settings/",
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: data,
      }),
    }),
  };
};
