export const authEndpoints = (builder) => {
  return {
    login: builder.mutation({
      query: ({ username, password }) => ({
        url: "dj-rest-auth/login/",
        method: "POST",
        body: { username, password },
      }),
    }),
    passwordChange: builder.mutation({
      query: ({ old_password, new_password1, new_password2 }) => ({
        url: "dj-rest-auth/password/change/",
        method: "POST",
        body: { old_password, new_password1, new_password2 },
      }),
    }),
    passwordReset: builder.mutation({
      query: ({ email }) => ({
        url: "dj-rest-auth/password/reset/",
        method: "POST",
        body: { email },
      }),
    }),
    passwordResetConfirm: builder.mutation({
      query: ({ newPassword, newPasswordConfirmed, token, uid }) => ({
        url: "dj-rest-auth/password/reset/confirm/",
        method: "POST",
        body: {
          new_password1: newPassword,
          new_password2: newPasswordConfirmed,
          token,
          uid,
        },
      }),
    }),
  };
};
